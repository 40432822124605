import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ScriptsModule, CepModule, LoadingModule, AlertModule } from 'app/shared/services';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule } from './pipes/pipes.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';

@NgModule({
  imports: [
    CommonModule,
    AlertModule,
    LoadingModule,
    CepModule,
    HttpClientModule,
    FormsModule,
    TextMaskModule,
    ScriptsModule,
    PipesModule,
    MatSnackBarModule,
    MatDialogModule,
    MatBottomSheetModule,
  ],
  exports: [CommonModule, AlertModule, LoadingModule, CepModule, HttpClientModule, FormsModule, TextMaskModule, ScriptsModule, PipesModule],
  declarations: [],
})
export class SharedModule {}
