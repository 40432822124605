import { Component, OnInit, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseService } from 'app/shared/services/base';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { Carrinho } from '@app/data/carrinho/carrinho.model';
import { CarrinhoService } from '@app/pages/carrinho/carrinho.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseService implements OnInit {
  public vendedorUrl: string = environment.VENDEDOR_URL;
  public search: string;
  public customClass: string = '';
  public carrinhoWatcher: Subscription;
  public carrinho: Carrinho;

  constructor(private _base: Injector, private _router: Router, private _carrinhoService: CarrinhoService) {
    super(_base);
  }

  public toggleSideNav() {
    this._sideNav.toggle();
  }

  public get produtosNoCarrinho(): number {
    return this.carrinho?.itens?.length;
  }

  ngOnInit() {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('blackfriday')) {
          this.customClass = 'blackfriday';
        } else {
          this.customClass = '';
        }
      }
    });
    this.watchCart();
  }

  public buscar(event): void {
    this._router.navigate(['/busca', event.target.text, '']);
  }

  public desapegar(): void {
    this._router.navigate(['/desapegar']);
  }

  public goToSeachPage(): void {
    if (this.search !== '') {
      this._router.navigate(['/busca', this.search.toLowerCase()]);
    } else {
      this._router.navigateByUrl('/busca');
    }
  }

  private watchCart(): void {
    this.carrinhoWatcher = this._carrinhoService.carrinho.subscribe((carrinho) => {
      this.carrinho = carrinho;
    });
  }
}
