import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LinkMagicoService } from './linkmagico.service';
import { BaseService } from '@app/shared/services/base';

@Component({
  templateUrl: './linkmagico.component.html',
})
export class LinkMagicoComponent implements OnInit {
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _linkMagicoService: LinkMagicoService,
    private _base: BaseService
  ) {}

  ngOnInit(): void {
    this._login();
  }

  private async _login() {
    this._base._loading.start();
    const chave = this._activatedRoute.snapshot.params.chave;

    try {
      await this._linkMagicoService.loginComLinkMagico(chave);
      this._base._loading.stop();
      this._router.navigateByUrl('/conta');
    } catch (err) {
      this._base._loading.stop();
      this._base._alert.error('Atenção', err);
      this._router.navigateByUrl('/conta');
    }
  }
}
