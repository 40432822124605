<div id="wrapper">
  <mat-drawer-container hasBackdrop="true">
    <mat-drawer #sidenav position="start" (click)="toggleSideNav()" fixedInViewport="true">
      <app-drawer></app-drawer>
    </mat-drawer>

    <mat-drawer-content>
      <app-loading></app-loading>
      <app-header></app-header>
      <div id="dashboard">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
