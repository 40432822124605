import { ErrorRoutes } from './error.routing';
import { ErrorService } from './error.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './error/';
import { DeniedComponent } from './denied/denied.component';
import { LayoutModule } from 'app/layout/layout.module';

@NgModule({
    imports: [
        CommonModule,
        ErrorRoutes,
        LayoutModule
    ],
    declarations: [PageNotFoundComponent, DeniedComponent],
    providers: [ErrorService]
})
export class ErrorModule {

}
