<mat-list>
  <mat-list-item matRipple>
    <mat-icon>close</mat-icon>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/conta/dashboard">Visão geral</a>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/favoritos">Favoritos</a>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/conta/perfil">Meu perfil</a>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/conta/meus-produtos">Meus produtos</a>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/conta/minhas-compras">Minhas compras</a>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/conta/minhas-vendas">Minhas Vendas</a>
  </mat-list-item>
  <mat-list-item matRipple>
    <a routerLink="/conta/carteira">Carteira</a>
  </mat-list-item>

  <mat-divider></mat-divider>
  <mat-list-item matRipple>
    <a routerLink="/faq">Dúvidas</a>
  </mat-list-item>
</mat-list>
