import { BaseService } from 'app/shared/services/base';
import { UsuarioService } from 'app/data';
import { Injectable, Injector } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
@Injectable()
export class AuthGuard extends BaseService implements CanActivate {
  constructor(private _base: Injector, private authService: AuthService, private usuarioServ: UsuarioService, private router: Router) {
    super(_base);
  }
  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.authService.usuarioApi.getCurrent().subscribe(
        () => {
          resolve(true);
        },
        () => {
          this.router.navigate(['/auth'], { queryParams: { returnUrl: state.url } });
          resolve(false);
        }
      );
    });
  }
  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
}
