import { gql } from 'apollo-angular';
import { Faq } from './faq.model';

export const FAQ_VIEW = gql`
mutation ViewFaq($faq: CreatFaqInputType!) {
    faq(faq: $faq) {
        id
        pergunta
        resposta
    }
}
`;

export interface ViewFaqResponse {
    faq: Faq;
    loading: boolean;
}
