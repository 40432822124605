<form name="loginForm" class="login" [formGroup]="loginForm">
  <h1>Login</h1>
  <div class="row">
    <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
      <mat-label>Email:</mat-label>
      <input
        type="email"
        placeholder="exemplo@exemplo.com"
        id="email"
        name="email"
        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
        formControlName="email"
        matInput
      />
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
      <mat-label>Senha:</mat-label>
      <input type="password" id="password" name="password" formControlName="password" matInput />
    </mat-form-field>
  </div>
  <button type="submit" mat-raised-button [disabled]="loginForm.invalid" (click)="login()" color="primary">Login</button>

  <button type="button" mat-button routerLink="/redefinir-senha">Esqueci minha senha</button>
</form>
