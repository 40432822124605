import { Usuario } from '@app/api';
import { CarrinhoItem } from '../carrinhoItem/carrinho-item.model';

export class Carrinho {
  id: string;
  sessaoId: string;
  usuarioId: string;
  vendaId: string;
  valorTotalDosItensSemFrete: number;
  valorTotalDosItensComFrete: number;
  valorFrete: number;
  status: string;
  quantidadeItens: number;
  itens: CarrinhoItem[];
  usuario: Usuario;
  retirarNaLoja?: boolean = false;

  constructor(data?: Partial<Carrinho>) {
    Object.assign(this, data);
  }
}
