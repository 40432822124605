import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-quero-desocupar',
  templateUrl: './quero-desocupar.component.html',
  styleUrls: ['./quero-desocupar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueroDesocuparComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
