import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Campanha } from '@app/data/campanha';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HomeService } from '../home.service';
import { finalize, take } from 'rxjs/operators';
import { error } from 'console';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit {
  constructor(private _service: HomeService, private _cdk: ChangeDetectorRef) {}

  public campanhas: Array<Campanha> = [];
  public campanhasCarregadas = false;

  public carrouselBanner: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 900,
    autoplay: true,
    autoHeight: false,
    autoplayHoverPause: true,
    skip_validateItems: true,
    fluidSpeed: true,
    lazyLoad: true,
    autoplaySpeed: 1000,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: false,
  };

  ngOnInit(): void {
    this._carregarCampanhas();
  }

  public get width() {
    return window.innerWidth;
  }

  public getCampanhaUrl(campanha: Campanha): string {
    if (campanha.tipo.toString() === 'LISTAGEM') {
      return `campanha/${campanha.url}`;
    }

    return campanha.url;
  }

  private _carregarCampanhas(): void {
    this._service
      .carregarCampanhasAtivas()
      .pipe(
        take(1),
        finalize(() => {
          this.campanhasCarregadas = true;
          this._cdk.markForCheck();
        })
      )
      .subscribe(({ data }) => (this.campanhas = data.query));
  }
}

const DestaqueParaValor = {
  ALTO: 3,
  MEDIO: 2,
  BAIXO: 1,
};
