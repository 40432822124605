import { Router } from '@angular/router';
import { ToastService } from 'app/shared/services';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
  constructor(private router: Router) {
  }

  public treat(error: any): void {
    switch (error.statusCode) {
      case 404:
        this.router.navigate(['404']);
        break;
      case 401:
        this.router.navigate(['401']);
        break;
    default:
    }
  }
}
