import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoopBackConfig } from '../api';
import { environment } from 'environments/environment';

@NgModule({
  imports: [CommonModule],
  declarations: [],
})
export class DataModule {
  constructor() {
    LoopBackConfig.setBaseURL(environment.API_URL);
    LoopBackConfig.setApiVersion(environment.API_VERSION);
    LoopBackConfig.setDebugMode(environment.API_DEBUG);
  }

  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: DataModule,
      providers: [],
    };
  }
}
