import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cep } from './cep.interface';
import { Apollo, gql } from 'apollo-angular';
import { EnderecoComDistancia } from '@app/models';
import { ApolloQueryResult } from "@apollo/client/core";
import { Observable } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(
    private http: HttpClient,
    private _apollo: Apollo,
  ) { }

  public async searchCep(cep: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.http
        .get<Cep>(`https://viacep.com.br/ws/${cep}/json`)
        .toPromise()
        .then((response: any) => {
          console.log({ response });
          if (response.erro) {
            const error = new Error();
            error.message = 'CEP não encontrado';
            reject(error);
          } else {
            const data = {
              bairro: response.bairro,
              cep: response.cep,
              cidade: response.localidade,
              complemento: response.complemento,
              estado: response.uf,
              logradouro: response.logradouro,
            };
            resolve(data);
          }
        })
        .catch((err: any) => {
          const error = new Error();

          error.message = 'CEP não encontrado';
          reject(error);
        });
    });
  }

  public consultarDistancia(cep: string): Observable<ApolloQueryResult<GetEnderecoComDistancia>> {
    const query = gql`
      query consultarDistancia($cep: String!) {
        consultarDistancia(cep: $cep) {
          logradouro
          cep
          bairro
          cidade
          complemento
          distancia_km
          tempo
        }
      }
    `;

    return this._apollo
      .query<GetEnderecoComDistancia>({
        query,
        variables: { cep },
      })
  }
}

export interface GetEnderecoComDistancia {
  consultarDistancia: EnderecoComDistancia;
}
