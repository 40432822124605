import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Campanha } from '@app/data/campanha';
import { GraphqlQuerySemContagem } from '@app/data/common/respostas.graphql';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {
  constructor(private _apollo: Apollo) {}

  public carregarCampanhasAtivas(): GraphqlQuerySemContagem<Array<Campanha>> {
    const query = gql`
      query campanhasAtivas {
        query: campanhasAtivas {
          id
          url
          bannerWebUrl
          bannerMobileUrl
          tituloCampanha
          tipo
          destaque
        }
      }
    `;

    return this._apollo.query<any>({
      query,
    });
  }
}
