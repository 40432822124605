import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/auth/auth.guard';
import { LelloLabColabComponent } from './landing-pages/lellolab-colab/lellolab-colab.component.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'loja',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'produto',
    loadChildren: () => import('./produto/produto.module').then((m) => m.ProdutoModule),
  },
  {
    path: 'produtos',
    loadChildren: () => import('./produtos/produtos.module').then((m) => m.ProdutosModule),
  },
  {
    path: 'smart-link',
    loadChildren: () => import('./smart-link/smart-link.module').then((m) => m.SmartLinkModule),
  },
  {
    path: 'produtos-usuario',
    loadChildren: () => import('./produtos-usuario/produtos-usuario.module').then((m) => m.ProdutosUsuarioModule),
  },
  {
    path: 'hubs',
    loadChildren: () => import('./hubs/hubs.module').then((m) => m.HubsModule),
  },
  {
    path: 'marcas',
    loadChildren: () => import('./marcas/marcas.module').then((m) => m.MarcasModule),
  },

  {
    path: 'secoes',
    loadChildren: () => import('./produtos-secao/produtos-secao.module').then((m) => m.ProdutosSecaoModule),
  },

  {
    path: 'sobre',
    loadChildren: () => import('./landing-pages/sobre/sobre.module').then((m) => m.SobreModule),
  },
  { path: 'home', loadChildren: () => import('./home/home.module').then((m) => m.HomeModule) },
  {
    path: 'contato',
    loadChildren: () => import('./contato/contato.module').then((m) => m.ContatoModule),
  },

  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then((m) => m.FaqModule),
  },

  {
    path: 'pesquisar',
    loadChildren: () => import('./pesquisar/pesquisar.module').then((m) => m.PesquisarModule),
  },
  {
    path: 'conta',
    loadChildren: () => import('./conta/conta.module').then((m) => m.ContaModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quero-vender-alternativa',
    loadChildren: () => import('./quero-vender/quero-vender.module').then((m) => m.QueroVenderModule),
  },
  {
    path: 'favoritos',
    loadChildren: () => import('./favoritos/favoritos.module').then((m) => m.FavoritosModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'politica-reembolso',
    loadChildren: () => import('./politica-reembolso/politica-reembolso.module').then((m) => m.PoliticaReembolsoModule),
  },
  {
    path: 'termos-de-uso',
    loadChildren: () => import('./termos-de-uso/termos-de-uso.module').then((m) => m.TermosDeUsoModule),
  },
  {
    path: 'invoice/:id',
    loadChildren: () => import('./invoice/invoice.module').then((m) => m.InvoiceModule),
  },

  { path: 'busca', loadChildren: () => import('./busca/busca.module').then((m) => m.BuscaModule) },
  { path: 'busca-alocados', loadChildren: () => import('./busca-alocados/busca-alocados.module').then((m) => m.BuscaAlocadosModule) },
  {
    path: 'carrinho',
    loadChildren: () => import('./carrinho/carrinho.module').then((m) => m.CarrinhoModule),
  },
  {
    path: 'produto-indisponivel',
    loadChildren: () => import('./produto-indisponivel/produto-indisponivel.module').then((m) => m.ProdutoIndisponivelModule),
  },
  {
    path: 'produtos-b2b',
    loadChildren: () => import('./produtos-b2b/produtos-b2b.module').then((m) => m.ProdutosB2BModule),
  },

  // landings
  {
    path: 'quinto-andar',
    loadChildren: () =>
      import('./landing-pages/landing-page-quinto-andar/landing-page-quinto-andar.module').then((m) => m.LandingPageQuintoAndarModule),
  },

  {
    path: 'obrigado-pela-compra',
    loadChildren: () => import('./obrigado-pela-compra/obrigado-pela-compra.module').then((mod) => mod.ObrigadoPelaCompraModule),
  },
  {
    path: 'tag-obras',
    loadChildren: () => import('./landing-pages/tag-obras/tag-obras.module').then((m) => m.TagObrasModule),
  },
  {
    path: 'doacao',
    loadChildren: () => import('./landing-pages/landing-page-doacao/landing-page-doacao.module').then((m) => m.LandingPageDoacaoModule),
  },
  {
    path: 'carreiras',
    loadChildren: () => import('./landing-pages/carreiras/carreiras.module').then((m) => m.CarreirasModule),
  },
  {
    path: 'trabalhe-conosco',
    loadChildren: () => import('./landing-pages/trabalhe-conosco/trabalhe-conosco.module').then((m) => m.TrabalheConoscoModule),
  },

  {
    path: 'lellolab',
    loadComponent: () => LelloLabColabComponent,
  },
];

export const PagesRoutingModule = RouterModule.forChild(routes);
