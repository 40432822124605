export interface ProdutoEstadoInterface {
  value: string;
  text: string;
}

export const produtoEstadosSelect: Array<ProdutoEstadoInterface> = [
  {
    value: 'S',
    text: 'Produto novo em embalagem original. Não possui avaria',
  },
  {
    value: 'A',
    text: 'A - Produtos sem avarias ou pequenas avarias em peças não aparentes',
  },
  {
    value: 'B',
    text: 'B - Produtos com pequenas avarias em peças aparentes',
  },
  {
    value: 'C',
    text: 'C - Produtos com médias avarias em peças aparentes',
  },
  {
    value: 'D',
    text: 'D - Produtos com falta de algum volume ou grandes avarias em qualquer peça',
  },
  {
    value: 'R',
    text: 'R - Recomenda-se reparos',
  },
];
