import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DeniedComponent } from './denied/denied.component';
import { PageNotFoundComponent } from './error/';

const routes: Routes = [
    { path: '404', component: PageNotFoundComponent },
    { path: '401', component: DeniedComponent }
];

export const ErrorRoutes = RouterModule.forChild(routes);
