<div class="section padding-top-65 padding-bottom-50">
  <div class="col-xs-12 col-sm-12 col-md-4"></div>
  <div class="col-xs-12 col-sm-12 col-md-4">
    <mat-card *ngIf="!states.requestSent && !states.alreadySendedEmail">
      <mat-card-content
        ><img src="assets/images/forgot.svg" class="reset-password-image" alt="" />
        <h3 class="reset-title center">Por favor, informe seu email.</h3>
        <h3 class="reset-title center">Nós enviaremos um link para atualizar sua senha.</h3>
        <form name="requestForm" (submit)="sendRequest($event, email.value)" novalidate #requestForm="ngForm" class="row">
          <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
            <mat-label>Email</mat-label>
            <input
              form="requestForm"
              ngModel
              type="email"
              id="email"
              #email
              name="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
              required
              autofocus
              matInput
            />
          </mat-form-field>
          <div class="col-xs-12 col-sm-12">
            <button type="submit" mat-raised-button color="primary" [disabled]="!requestForm.form.valid">Enviar</button>
          </div>
        </form>
      </mat-card-content>
      <mat-card-actions><button type="button" mat-flat-button routerLink="/login">Voltar para tela de login</button> </mat-card-actions>
    </mat-card>

    <mat-card *ngIf="!states.requestSent && states.alreadySendedEmail">
      <mat-card-content>
        <img src="assets/images/password.svg" class="reset-password-image" alt="" />
        <h3 class="col-xs-12 col-sm-12 text-center">Redefinir senha</h3>
        <form name="resetForm" (submit)="createNewPassword($event, password.value, confirm.value)" novalidate #resetForm="ngForm">
          <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
            <mat-label>Nova senha</mat-label>
            <input matInput form="resetForm" ngModel type="password" id="password" #password name="password" required autofocus />
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
            <mat-label>Confirmar nova senha</mat-label>
            <input matInput form="resetForm" ngModel type="password" id="confirm" #confirm name="confirm" required />
          </mat-form-field>
          <button type="submit" mat-raised-button color="primary" [disabled]="!resetForm.form.valid">Alterar</button>
          <button type="button" mat-flat-button routerLink="/login">Voltar para tela de login</button>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>

<div *ngIf="states.requestSent" class="section padding-top-65 padding-bottom-50 email-sent">
  <img src="assets/images/mail.svg" alt="" />
  <h3 class="center">Email enviado. Confira a sua caixa de entrada.</h3>
  <button type="button" mat-raised-button routerLink="/login" color="primary">
    <mat-icon>keyboard_arrow_left</mat-icon>Voltar para tela de login
  </button>
</div>
