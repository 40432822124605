/* tslint:disable */
import { Hub, Transacao, Usuario, Produto, VendaItem, Pagamento } from '../index';

declare var Object: any;
export interface VendaInterface {
  id?: string;
  formaPagamento?: string;
  valorindividual?: number;
  valortotal?: number;
  valorTotalVenda?: number;
  valorTotalPago?: number;
  valorTotalItens?: number;
  valorrepassado?: number;
  dataVenda?: Date;
  quantidadeVendida: number;
  statusRepasse?: string;
  percentual?: number;
  compradorNome?: string;
  vendedor?: string;
  compradorContato?: string;
  status?: string;
  valorFrete?: number;
  taxaFinanciamento?: number;
  desconto?: number;
  reserva?: boolean;
  originLead?: string;
  origem?: string;
  observacoes?: string;
  checkout?: boolean;
  prevenda?: boolean;
  comercialAgendamentoId?: string;
  hubId?: string;
  usuarioId?: string;
  comercialId?: string;
  produtoId?: string;
  createdAt: Date;
  updatedAt: Date;
  hub?: Hub;
  transacao?: Transacao[];
  usuario?: Usuario;
  comercial?: Usuario;
  produto?: Produto;
  itens?: VendaItem[];
  pagamento?: Pagamento[];
}

export class Venda implements VendaInterface {
  'id': string;
  'formaPagamento': string;
  'valorindividual': number;
  'valortotal': number;
  'valorTotalVenda': number;
  'valorTotalPago': number;
  'valorTotalItens': number;
  'valorrepassado': number;
  'dataVenda': Date;
  'quantidadeVendida': number;
  'statusRepasse': string;
  'percentual': number;
  'compradorNome': string;
  'vendedor': string;
  'compradorContato': string;
  'status': string;
  'valorFrete': number;
  'taxaFinanciamento': number;
  'desconto': number;
  'reserva': boolean;
  'originLead': string;
  'origem': string;
  'observacoes': string;
  'checkout': boolean;
  'prevenda': boolean;
  'comercialAgendamentoId': string;
  'hubId': string;
  'usuarioId': string;
  'comercialId': string;
  'produtoId': string;
  'createdAt': Date;
  'updatedAt': Date;
  hub: Hub;
  transacao: Transacao[];
  usuario: Usuario;
  comercial: Usuario;
  produto: Produto;
  itens: VendaItem[];
  pagamento: Pagamento[];
  constructor(data?: VendaInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Venda`.
   */
  public static getModelName() {
    return 'Venda';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Venda for dynamic purposes.
   **/
  public static factory(data: VendaInterface): Venda {
    return new Venda(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Venda',
      plural: 'Vendas',
      path: 'Vendas',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        formaPagamento: {
          name: 'formaPagamento',
          type: 'string',
        },
        valorindividual: {
          name: 'valorindividual',
          type: 'number',
          default: 0,
        },
        valortotal: {
          name: 'valortotal',
          type: 'number',
          default: 0,
        },
        valorTotalVenda: {
          name: 'valorTotalVenda',
          type: 'number',
          default: 0,
        },
        valorTotalPago: {
          name: 'valorTotalPago',
          type: 'number',
          default: 0,
        },
        valorTotalItens: {
          name: 'valorTotalItens',
          type: 'number',
          default: 0,
        },
        valorrepassado: {
          name: 'valorrepassado',
          type: 'number',
          default: 0,
        },
        dataVenda: {
          name: 'dataVenda',
          type: 'Date',
        },
        quantidadeVendida: {
          name: 'quantidadeVendida',
          type: 'number',
          default: 1,
        },
        statusRepasse: {
          name: 'statusRepasse',
          type: 'string',
        },
        percentual: {
          name: 'percentual',
          type: 'number',
          default: 50,
        },
        compradorNome: {
          name: 'compradorNome',
          type: 'string',
          default: 'Sem dados',
        },
        vendedor: {
          name: 'vendedor',
          type: 'string',
        },
        compradorContato: {
          name: 'compradorContato',
          type: 'string',
          default: '0',
        },
        status: {
          name: 'status',
          type: 'string',
        },
        valorFrete: {
          name: 'valorFrete',
          type: 'number',
          default: 0,
        },
        taxaFinanciamento: {
          name: 'taxaFinanciamento',
          type: 'number',
          default: 0,
        },
        desconto: {
          name: 'desconto',
          type: 'number',
          default: 0,
        },
        reserva: {
          name: 'reserva',
          type: 'boolean',
          default: false,
        },
        originLead: {
          name: 'originLead',
          type: 'string',
        },
        origem: {
          name: 'origem',
          type: 'string',
        },
        observacoes: {
          name: 'observacoes',
          type: 'string',
        },
        checkout: {
          name: 'checkout',
          type: 'boolean',
          default: false,
        },
        prevenda: {
          name: 'prevenda',
          type: 'boolean',
          default: false,
        },
        comercialAgendamentoId: {
          name: 'comercialAgendamentoId',
          type: 'string',
        },
        hubId: {
          name: 'hubId',
          type: 'string',
        },
        usuarioId: {
          name: 'usuarioId',
          type: 'string',
        },
        comercialId: {
          name: 'comercialId',
          type: 'string',
        },
        produtoId: {
          name: 'produtoId',
          type: 'string',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
      },
      relations: {
        hub: {
          name: 'hub',
          type: 'Hub',
          model: 'Hub',
          relationType: 'belongsTo',
          keyFrom: 'hubId',
          keyTo: 'id',
        },
        transacao: {
          name: 'transacao',
          type: 'Transacao[]',
          model: 'Transacao',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'vendaId',
        },
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
          keyFrom: 'usuarioId',
          keyTo: 'id',
        },
        comercial: {
          name: 'comercial',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
          keyFrom: 'comercialId',
          keyTo: 'id',
        },
        produto: {
          name: 'produto',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
          keyFrom: 'produtoId',
          keyTo: 'id',
        },
        itens: {
          name: 'itens',
          type: 'VendaItem[]',
          model: 'VendaItem',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'vendaId',
        },
        pagamento: {
          name: 'pagamento',
          type: 'Pagamento[]',
          model: 'Pagamento',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'vendaId',
        },
      },
    };
  }
}
