

<div class="container auth">
    <div class="row">
        <form name="changeForm" (submit)="changepassword($event)" #changeForm="ngForm">
            <div class="col-md-4 col-md-offset-4">
                <div class="login-panel panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">Change Password</h3>
                    </div>
                    <div class="panel-body">
                      <div class="form-group">
                          <label for="actual" class="rl-label">Current Password</label>
                          <input form="changeForm" [(ngModel)]="actual" type="password" id="actual"  class="form-control" name="actual" required autofocus>
                      </div>
                      <div class="form-group">
                          <label for="password" class="rl-label">New password</label>
                          <input form="changeForm" [(ngModel)]="password" type="password" id="password" class="form-control" (input)="validPassord()" (blur)="validPassord()" name="password" required>
                      </div>
                      <div class="form-group">
                        <label for="confirm" class="rl-label">Confirm new password</label>
                        <input form="changeForm" [(ngModel)]="confirm" type="password" id="confirm" class="form-control" (input)="validPassord()" (blur)="validPassord()" [ngClass]="passwordEqual ? 'ng-invalid' : 'ng-valid'"  name="confirm" required>
                      </div>
                      <button type="submit" class="btn btn-md btn-success btn-block" [disabled]="!changeForm.form.valid && !passwordEqual">Change now</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
