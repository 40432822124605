import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlackWeekComponent } from './black-week.component';
import { BlackWeekRoutingModule } from './black-week.routes';

@NgModule({
  declarations: [BlackWeekComponent],
  imports: [CommonModule, BlackWeekRoutingModule],
  exports: [BlackWeekComponent],
  providers: [],
})
export class BlackWeekModule { }