import { Component, OnInit, Injector } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieBrowser, SDKToken } from 'app/api';
import { BaseService } from 'app/shared/services/base';
import { AuthService } from 'app/auth/auth.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['reset.component.scss'],
})
export class ResetComponent extends BaseService implements OnInit {
  public states = {
    alreadySendedEmail: false,
    requestSent: false,
  };

  public accessToken: SDKToken;

  constructor(
    private _base: Injector,
    private authService: AuthService,
    private _router: Router,
    private activeRoute: ActivatedRoute,
    private cookie: CookieBrowser
  ) {
    super(_base);
  }

  public ngOnInit(): void {
    this.checkUrl();
  }

  private checkUrl(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      console.log(params);

      if (!!params.access_token) {
        if (localStorage.getItem('alreadyLoaded') === undefined) {
          localStorage.setItem('alreadyLoaded', 'true');
          location.reload();
        }

        this.accessToken = params.access_token;

        this.states.alreadySendedEmail = true;
      }
    });
  }

  public createNewPassword(event: any, password: string, confirm: string): void {
    event.preventDefault();

    if (password !== confirm) {
      this._toast.error('Senhas não conferem!');
      return;
    }

    if (password.length < 8) {
      this._toast.error('Digite uma senha com mais que 8 caracteres');
      return;
    }

    console.log(this.accessToken);

    this.authService
      .setPassword(password, this.accessToken)
      .then((res) => {
        this._toast.success('Senha alterada com sucesso', 'Yay!');
        this._router.navigateByUrl('/');
      })
      .catch((err) => {
        if (err.statusCode === 401) {
          this._toast.error('Token inválido. Faça uma nova requisição.');
        } else {
          this._toast.error(err.message);
        }
      });
  }

  public async sendRequest(event, email): Promise<void> {
    event.preventDefault();
    await this.authService
      .reset(email)
      .then(() => {
        this._toast.success('Email enviado!');
        this.states.requestSent = true;
      })
      .catch((error) => {
        if (error.code === 'RESET_FAILED_EMAIL_NOT_VERIFIED') {
          swal({
            title: 'Seu email ainda não foi verificado',
            text: 'Verifique seu email e confirme seu cadastro!',
            imageUrl: '/images/envelope.png',
            imageWidth: 60,
            imageHeight: 60,
            confirmButtonText: 'OK',
          }).catch(swal.noop);
        } else {
          this._toast.error(error.message);
        }
      });
  }
}
