import { NgModule, ModuleWithProviders } from '@angular/core';

import { GuidService } from './guid.service';

@NgModule({})
export class GuidModule {
  constructor() {}

  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: GuidModule,
      providers: [GuidService],
    };
  }
}
