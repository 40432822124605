/* tslint:disable */
import { Hub, Usuario, SolicitacaoServico, Venda, VendaItem, ProdutoFotos, Categoria, Marca, Secao } from '../index';

declare var Object: any;
export interface ProdutoInterface {
  id?: string;
  codProduto?: string;
  estoque?: number;
  cor?: string;
  entrega?: string;
  aprovacao?: string;
  linkReferencia?: string;
  estado?: string;
  modelo?: string;
  marca?: string;
  classificacao?: string;
  titulo?: string;
  tituloComposto?: string;
  descricao?: string;
  altura?: string;
  largura?: string;
  profundidade?: string;
  partes?: string;
  codigoOrigem?: string;
  valormercado?: number;
  valorinicial?: number;
  valoratual?: number;
  valorsite?: number;
  valorsugerido?: number;
  valorlocacao?: number;
  valorcompratag?: number;
  valorpago?: number;
  status?: string;
  obs_status?: string;
  observacoes?: string;
  observacoesInternas?: string;
  material?: string;
  ncm?: string;
  codigoOmie?: string;
  segmento?: string;
  datapublicacao?: Date;
  datareanuncio?: Date;
  percentual?: number;
  url?: string;
  quantidadeDisponivel?: number;
  compraTag?: boolean;
  voltagem?: number;
  hubId?: string;
  usuarioId?: string;
  antigoProprietarioId?: string;
  visitaId?: string;
  createdAt: Date;
  updatedAt: Date;
  categoriaId?: string;
  marcaId?: string;
  hub?: Hub;
  usuario?: Usuario;
  antigoProprietario?: Usuario;
  visita?: SolicitacaoServico;
  venda?: Venda[];
  vendas?: VendaItem[];
  fotos?: ProdutoFotos[];
  categoria?: Categoria;
  fabricante?: Marca;
  secoes?: Secao[];
}

export class Produto implements ProdutoInterface {
  'id': string;
  'codProduto': string;
  'estoque': number;
  'cor': string;
  'entrega': string;
  'aprovacao': string;
  'linkReferencia': string;
  'estado': string;
  'modelo': string;
  'marca': string;
  'classificacao': string;
  'titulo': string;
  'tituloComposto': string;
  'descricao': string;
  'altura': string;
  'largura': string;
  'profundidade': string;
  'partes': string;
  'codigoOrigem': string;
  'valormercado': number;
  'valorinicial': number;
  'valoratual': number;
  'valorsite': number;
  'valorsugerido': number;
  'valorlocacao': number;
  'valorcompratag': number;
  'valorpago': number;
  'status': string;
  'obs_status': string;
  'observacoes': string;
  'observacoesInternas': string;
  'material': string;
  'materialPredominante': string;
  'materialSecundario': string;
  'ncm': string;
  'codigoOmie': string;
  'segmento': string;
  'datapublicacao': Date;
  'datareanuncio': Date;
  'percentual': number;
  'url': string;
  'quantidadeDisponivel': number;
  'compraTag': boolean;
  'voltagem': number;
  'hubId': string;
  'usuarioId': string;
  'antigoProprietarioId': string;
  'visitaId': string;
  'createdAt': Date;
  'updatedAt': Date;
  'categoriaId': string;
  'fotografoId': string;
  'marcaId': string;
  hub: Hub;
  usuario: Usuario;
  antigoProprietario: Usuario;
  visita: SolicitacaoServico;
  venda: Venda[];
  vendas: VendaItem[];
  fotos: ProdutoFotos[];
  categoria: Categoria;
  fabricante: Marca;
  secoes: Secao[];
  constructor(data?: ProdutoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Produto`.
   */
  public static getModelName() {
    return 'Produto';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Produto for dynamic purposes.
   **/
  public static factory(data: ProdutoInterface): Produto {
    return new Produto(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Produto',
      plural: 'Produtos',
      path: 'Produtos',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        codProduto: {
          name: 'codProduto',
          type: 'string',
        },
        estoque: {
          name: 'estoque',
          type: 'number',
          default: 1,
        },
        cor: {
          name: 'cor',
          type: 'string',
        },
        entrega: {
          name: 'entrega',
          type: 'string',
        },
        aprovacao: {
          name: 'aprovacao',
          type: 'string',
        },
        linkReferencia: {
          name: 'linkReferencia',
          type: 'string',
        },
        estado: {
          name: 'estado',
          type: 'string',
        },
        modelo: {
          name: 'modelo',
          type: 'string',
        },
        marca: {
          name: 'marca',
          type: 'string',
        },
        classificacao: {
          name: 'classificacao',
          type: 'string',
        },
        titulo: {
          name: 'titulo',
          type: 'string',
        },
        tituloComposto: {
          name: 'tituloComposto',
          type: 'string',
        },
        descricao: {
          name: 'descricao',
          type: 'string',
        },
        altura: {
          name: 'altura',
          type: 'string',
        },
        largura: {
          name: 'largura',
          type: 'string',
        },
        profundidade: {
          name: 'profundidade',
          type: 'string',
        },
        partes: {
          name: 'partes',
          type: 'string',
        },
        codigoOrigem: {
          name: 'codigoOrigem',
          type: 'string',
        },
        valormercado: {
          name: 'valormercado',
          type: 'number',
        },
        valorinicial: {
          name: 'valorinicial',
          type: 'number',
        },
        valoratual: {
          name: 'valoratual',
          type: 'number',
        },
        valorsite: {
          name: 'valorsite',
          type: 'number',
        },
        valorsugerido: {
          name: 'valorsugerido',
          type: 'number',
        },
        valorlocacao: {
          name: 'valorlocacao',
          type: 'number',
          default: 0,
        },
        valorcompratag: {
          name: 'valorcompratag',
          type: 'number',
        },
        valorpago: {
          name: 'valorpago',
          type: 'number',
        },
        status: {
          name: 'status',
          type: 'string',
        },
        obs_status: {
          name: 'obs_status',
          type: 'string',
        },
        observacoes: {
          name: 'observacoes',
          type: 'string',
        },
        observacoesInternas: {
          name: 'observacoesInternas',
          type: 'string',
        },
        material: {
          name: 'material',
          type: 'string',
        },
        ncm: {
          name: 'ncm',
          type: 'string',
        },
        codigoOmie: {
          name: 'codigoOmie',
          type: 'string',
        },
        segmento: {
          name: 'segmento',
          type: 'string',
        },
        datapublicacao: {
          name: 'datapublicacao',
          type: 'Date',
        },
        datareanuncio: {
          name: 'datareanuncio',
          type: 'Date',
        },
        percentual: {
          name: 'percentual',
          type: 'number',
          default: 50,
        },
        url: {
          name: 'url',
          type: 'string',
        },
        quantidadeDisponivel: {
          name: 'quantidadeDisponivel',
          type: 'number',
        },
        compraTag: {
          name: 'compraTag',
          type: 'boolean',
          default: false,
        },
        voltagem: {
          name: 'voltagem',
          type: 'number',
        },
        hubId: {
          name: 'hubId',
          type: 'string',
        },
        usuarioId: {
          name: 'usuarioId',
          type: 'string',
        },
        antigoProprietarioId: {
          name: 'antigoProprietarioId',
          type: 'string',
        },
        visitaId: {
          name: 'visitaId',
          type: 'string',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        categoriaId: {
          name: 'categoriaId',
          type: 'string',
        },
        marcaId: {
          name: 'marcaId',
          type: 'string',
        },
      },
      relations: {
        hub: {
          name: 'hub',
          type: 'Hub',
          model: 'Hub',
          relationType: 'belongsTo',
          keyFrom: 'hubId',
          keyTo: 'id',
        },
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
          keyFrom: 'usuarioId',
          keyTo: 'id',
        },
        antigoProprietario: {
          name: 'antigoProprietario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
          keyFrom: 'antigoProprietarioId',
          keyTo: 'id',
        },
        visita: {
          name: 'visita',
          type: 'SolicitacaoServico',
          model: 'SolicitacaoServico',
          relationType: 'belongsTo',
          keyFrom: 'visitaId',
          keyTo: 'id',
        },
        venda: {
          name: 'venda',
          type: 'Venda[]',
          model: 'Venda',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'produtoId',
        },
        vendas: {
          name: 'vendas',
          type: 'VendaItem[]',
          model: 'VendaItem',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'produtoId',
        },

        fotos: {
          name: 'fotos',
          type: 'ProdutoFotos[]',
          model: 'ProdutoFotos',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'produtoId',
        },
        categoria: {
          name: 'categoria',
          type: 'Categoria',
          model: 'Categoria',
          relationType: 'belongsTo',
          keyFrom: 'categoriaId',
          keyTo: 'id',
        },
        fabricante: {
          name: 'fabricante',
          type: 'Marca',
          model: 'Marca',
          relationType: 'belongsTo',
          keyFrom: 'marcaId',
          keyTo: 'id',
        },
        secoes: {
          name: 'secoes',
          type: 'Secao[]',
          model: 'Secao',
          relationType: 'hasMany',
          modelThrough: 'SecoesEProdutos',
          keyThrough: 'secaoId',
          keyFrom: 'id',
          keyTo: 'produtoId',
        },
      },
    };
  }
}
