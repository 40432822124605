import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthRoutingModule } from './auth.routes';
import { AuthService } from './auth.service';
import { AuthGuard } from './auth.guard';

import { ChangepasswordComponent, RegisterComponent, ResetComponent, SetpasswordComponent } from 'app/auth/';
import { MatTabsModule } from '@angular/material/tabs';

import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';
import { RegisterLoginComponent } from './register-login/register-login.component';
import { CookieBrowser } from '@app/api';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { RegisterVendedorComponent } from './register-vendedor/register-vendedor.component';
import { LinkMagicoComponent } from './linkmagico/linkmagico.component';
import { LinkMagicoService } from './linkmagico/linkmagico.service';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HttpClientModule } from '@angular/common/http';
import { RegisterVendedorService } from './register-vendedor/register-vendedor.service';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    MatButtonToggleModule,
    LayoutModule,
    SharedModule,
    MatGridListModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatCardModule,
    MatTabsModule,
    HttpClientModule,
    MatMenuModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ChangepasswordComponent,
    ResetComponent,
    SetpasswordComponent,
    RegisterLoginComponent,
    RegisterComponent,
    RegisterVendedorComponent,
    LinkMagicoComponent,
  ],
  exports: [
    ChangepasswordComponent,
    ResetComponent,
    SetpasswordComponent,
    RegisterLoginComponent,
    RegisterComponent,
    RegisterVendedorComponent,
    LinkMagicoComponent,
  ],
  providers: [AuthService, AuthGuard, CookieBrowser, RegisterVendedorService, LinkMagicoService],
})
export class AuthModule {}
