<div class="container">
  <div class="login-panel panel panel-default col-s">
    <amp-img class="center" class="infografico" src="https://d2297a7qukasmq.cloudfront.net/assets/infografico.webp" width="500" height="700">
    </amp-img>
    <br />
    <br />
    <h1 class="text-center">Ficou com alguma dúvida?</h1>
    <h3 class="text-center"><a routerLink="/faq">Acesse o FAQ clicando aqui</a> ou nos envie uma mensagem!</h3>
    <br /><br />
    <div class="panel-body">
      <!-- <h2 class="text-center">Se está tudo certo, nos informe alguns dados básicos e entraremos em contato o mais breve possível:</h2> -->
      <br />

      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-xs-12 col-md-6">
          <div class="row">
            <a class="wpp" [href]="link" class="col-xs-12" group="whatsapp" title="Falar com atendente">
              <div class="btn-wpp">
                <img src="/assets/images/wpp-painel-produto.svg" id="wpp-icon" aria-hidden="true" />
                <h4>Falar com atendente</h4>
              </div>
            </a>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-md-2"></div>
        <div class="col-sm-12 col-md-8">
          <form [formGroup]="leadForm" (submit)="checkData($event)" novalidate #f="ngForm">
            <div class="row">
              <mat-form-field class="col-sm-12" appearance="outline">
                <mat-label>Nome</mat-label>
                <input matInput formControlName="nome" type="text" placeholder="Nome" id="nome" name="nome" required />
                <mat-icon matSuffix>account_circle</mat-icon>
              </mat-form-field>

              <mat-form-field class="col-sm-12" appearance="outline">
                <mat-label>Contato</mat-label>
                <input
                  type="tel"
                  matInput
                  formControlName="telefone"
                  id="compradorContato"
                  placeholder="Contato"
                  name="compradorContato"
                  autocomplete="off"
                  pattern="[(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})]*"
                  mask="(00) 0 0000 0000"
                  required
                />
                <mat-icon matSuffix>contact_phone</mat-icon>
              </mat-form-field>

              <mat-form-field class="col-sm-12" appearance="outline">
                <mat-label>Email</mat-label>
                <input
                  matInput
                  type="email"
                  formControlName="email"
                  placeholder="Email"
                  id="email"
                  name="email"
                  oninput="this.value = this.value.toLowerCase()"
                  required
                />
                <mat-icon matSuffix>contact_mail</mat-icon>
              </mat-form-field>

              <div class="col-sm-12">
                <img *ngIf="loading" class="loading" src="/assets/images/loader.svg" alt="carregando..." />

                <button *ngIf="!loading" type="submit" mat-raised-button color="primary" [disabled]="!f.form.valid">Enviar dados</button>
              </div>
            </div>
          </form>
        </div>
      </div> -->
    </div>
  </div>
</div>
