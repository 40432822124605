/* tslint:disable */
import { Injectable } from '@angular/core';
import { Transacao } from '../../models/Transacao';
import { Usuario } from '../../models/Usuario';
import { Hub } from '../../models/Hub';
import { Produto } from '../../models/Produto';
import { Venda } from '../../models/Venda';
import { VendaItem } from '../../models/VendaItem';
import { Carteira } from '../../models/Carteira';
import { ProdutoFotos } from '../../models/ProdutoFotos';
import { Categoria } from '../../models/Categoria';
import { Pagamento } from '../../models/Pagamento';
import { Endereco } from '../../models/Endereco';
import { DadosBancario } from '../../models/DadosBancario';
import { Marca } from '../../models/Marca';
import { Secao } from '../../models/Secao';
import { SecoesEProdutos } from '../../models/SecoesEProdutos';

export interface Models {
  [name: string]: any;
}

@Injectable()
export class SDKModels {
  private models: Models = {
    Transacao: Transacao,
    Usuario: Usuario,
    Hub: Hub,
    Produto: Produto,
    Venda: Venda,
    VendaItem: VendaItem,
    Carteira: Carteira,
    ProdutoFotos: ProdutoFotos,
    Categoria: Categoria,
    Pagamento: Pagamento,
    Endereco: Endereco,
    DadosBancario: DadosBancario,
    Marca: Marca,
    Secao: Secao,
    SecoesEProdutos: SecoesEProdutos,
  };

  public get(modelName: string): any {
    return this.models[modelName];
  }

  public getAll(): Models {
    return this.models;
  }

  public getModelNames(): string[] {
    return Object.keys(this.models);
  }
}
