<div class="banners">
  <owl-carousel-o [options]="carrouselBanner">
    <ng-template carouselSlide>
      <a href="https://link.tag2u.com.br/geral-banner-whats-comercial-101224" target="_blank">
        <img src="https://tag2u.s3-sa-east-1.amazonaws.com/campanha/a48faa11/desktop.jpg" alt="QRcode Grupo" *ngIf="width > 868" />
        <img src="https://tag2u.s3-sa-east-1.amazonaws.com/campanha/a48faa11/mobile.jpg" alt="QRcode Grupo" *ngIf="width < 868" />
      </a>
    </ng-template>
    <ng-template carouselSlide>
      <a href="https://link.tag2u.com.br/natal-banner-site-clube-ofertas-091224" target="_blank">
        <img src="https://tag2u.s3-sa-east-1.amazonaws.com/campanha/d5c9308b/desktop.jpg" alt="QRcode Grupo" *ngIf="width > 868" />
        <img src="https://tag2u.s3-sa-east-1.amazonaws.com/campanha/d5c9308b/mobile.jpg" alt="QRcode Grupo" *ngIf="width < 868" />
      </a>
    </ng-template>
    <ng-template carouselSlide>
      <a href="https://link.tag2u.com.br/banner-outlet-site-121224" target="_blank">
        <img src="https://tag2u.s3-sa-east-1.amazonaws.com/campanha/d59dac5d/desktop.jpg" alt="QRcode Grupo" *ngIf="width > 868" />
        <img src="https://tag2u.s3-sa-east-1.amazonaws.com/campanha/d59dac5d/mobile.jpg" alt="QRcode Grupo" *ngIf="width < 868" />
      </a>
    </ng-template>
    <ng-template carouselSlide *ngFor="let campanha of campanhas">
      <a routerLink="{{ getCampanhaUrl(campanha) }}">
        <img [src]="campanha?.bannerWebUrl" [alt]="campanha.tituloCampanha" *ngIf="width > 868" />
        <img [src]="campanha?.bannerMobileUrl" [alt]="campanha.tituloCampanha" *ngIf="width < 868" />
      </a>
    </ng-template>
  </owl-carousel-o>
</div>

<div *ngIf="!campanhasCarregadas" class="placeholder shimmer"></div>
