import { Injectable } from "@angular/core";
import { ApolloQueryResult } from "@apollo/client/core";
import { Apollo } from "apollo-angular";
import { Faq, FAQ_COUNT, FAQ_QUERY, GetFaqByQueryResponse, GET_FAQ_BY_ID, quantidadeFaqQueryResult } from "./graphql";
import { Observable } from 'rxjs'
import { GraphqlPagination } from "../common/pagination.graphql";

@Injectable({
  providedIn: 'root'
})

export class FAQService {

  constructor(private apollo: Apollo) { }

  public faq(): Observable<ApolloQueryResult<Faq>> {
    return this.apollo.query<any>({
      query: FAQ_QUERY,
    });
  }

  public getFaqById(id: string): Observable<ApolloQueryResult<GetFaqByQueryResponse>> {
    return this.apollo.query<any>({
      query: GET_FAQ_BY_ID,
      variables: { id },
    })
  }


  public count(faq?: Partial<Faq>): Observable<ApolloQueryResult<quantidadeFaqQueryResult>> {
    return this.apollo.query<any>({
      query: FAQ_COUNT,
      variables: { ...faq },
    });
  }

  public find(
    faq?: Partial<Faq>,
    skip?: number,
    pagination?: GraphqlPagination
  ): Observable<ApolloQueryResult<GetFaqByQueryResponse>> {
    return this.apollo.query<any>({
      query: FAQ_QUERY,
      variables: {
        where: {
          ...faq
        },
        pagination: {
          skip
        }
      }
    });
  }


  // public async sendEmail(email): Promise<any> {
  //    return new Promise((resolve, reject) => {
  //     this._faqApi.sendMail(email).toPromise()
  //     .then( () => resolve())
  //     .catch(err => reject(err));
  //    });
  // }

}
