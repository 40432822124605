<!-- <button mat-raised-button color="primary">Quero vender</button>
  <button mat-raised-button color="primary">Quero comprar</button> -->
<br />

<div class="col-xs-12 col-md-6 vender" *ngIf="!formularioVisivel">
  <mat-card>
    <br />
    <img width="340" src="https://d2297a7qukasmq.cloudfront.net/assets/comprar.svg" alt="" />
    <br />
    <br />

    <button mat-raised-button color="primary" (click)="exibirForm()">Quero comprar</button>
  </mat-card>
</div>

<div class="col-xs-12 col-md-6 vender" *ngIf="!formularioVisivel">
  <mat-card>
    <br />
    <img width="400" src="https://d2297a7qukasmq.cloudfront.net/assets/vender.svg" alt="" />
    <br />
    <br />
    <a mat-raised-button color="primary" routerLink="/quero-vender" (click)="ocultarForm()">Quero vender</a>
  </mat-card>
</div>

<form name="loginForm" (submit)="register($event)" novalidate #f="ngForm" class="row" *ngIf="formularioVisivel">
  <br />

  <div class="col-xs-12 col-sm-12">
    <h3>
      <p>Vamos criar uma conta!</p>
    </h3>

    <br />
    <br />
  </div>

  <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
    <mat-label>Nome</mat-label>
    <input matInput form="loginForm" [(ngModel)]="usuario.nome" placeholder="João" type="text" id="nome" name="nome" required />
  </mat-form-field>

  <mat-form-field class="col-xs-12 col-sm-12 col-md-9" appearance="outline">
    <mat-label>Contato</mat-label>

    <input
      type="tel"
      matInput
      *ngIf="contactType == contactsEnum.TELEPHONE"
      [(ngModel)]="usuario.celular"
      id="compradorContato"
      #compradorContato
      placeholder="Contato"
      name="compradorContato"
      autocomplete="off"
      [textMask]="{ mask: masks.telefone }"
      required
    />
    <input
      type="tel"
      matInput
      *ngIf="contactType == contactsEnum.MOBILE"
      [(ngModel)]="usuario.celular"
      id="compradorContato"
      #compradorContato
      placeholder="Contato"
      name="compradorContato"
      autocomplete="off"
      pattern="[(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})]*"
      [textMask]="{ mask: masks.celular }"
      required
    />

    <input
      type="tel"
      matInput
      *ngIf="contactType == contactsEnum.ANOTHER"
      [(ngModel)]="usuario.celular"
      id="compradorContato"
      #compradorContato
      placeholder="Contato"
      name="compradorContato"
      pattern="[0-9]*"
      autocomplete="off"
      required
    />

    <mat-hint> É importante que o número informado seja um número de WhatsApp, pois é por onde o contato será realizado. </mat-hint>

    <mat-icon matSuffix *ngIf="contactType === contactsEnum.TELEPHONE">phone</mat-icon>
    <mat-icon matSuffix *ngIf="contactType !== contactsEnum.TELEPHONE">stay_current_portrait</mat-icon>
  </mat-form-field>

  <div class="col-xs-12 col-sm-12 col-md-3">
    <button
      mat-stroked-button
      [matMenuTriggerFor]="menu"
      aria-label="Selecionar tipo de telefone"
      type="button"
      style="height: 50px; margin-top: 3px"
    >
      {{ tipoContato }}
      <mat-icon>swap_horiz</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="setContactType(contactsEnum.MOBILE)">
        <mat-icon matSuffix>stay_current_portrait</mat-icon>
        <span>Celular (BR)</span>
      </button>
      <button mat-menu-item (click)="setContactType(contactsEnum.ANOTHER)">
        <mat-icon matSuffix>stay_current_portrait</mat-icon>

        <span>Celular (Outros)</span>
      </button>
    </mat-menu>
  </div>

  <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
    <mat-label>Email</mat-label>
    <input
      matInput
      form="loginForm"
      [(ngModel)]="usuario.email"
      placeholder="exemplo@exemplo.com"
      type="email"
      id="email"
      name="email"
      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
    <mat-label>Senha</mat-label>
    <input
      matInput
      form="loginForm"
      [(ngModel)]="usuario.password"
      placeholder="Digite uma senha"
      type="password"
      id="password1"
      name="password"
      required
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="col-xs-12 col-sm-12">
    <mat-label>Repetir senha</mat-label>
    <input matInput form="loginForm" [(ngModel)]="confirm" placeholder="Repita a senha" type="password" id="confirm" name="confirm" required />
  </mat-form-field>

  <button mat-raised-button class="btn" type="submit" [disabled]="!f.form.valid">Cadastrar</button>
</form>

<br />
