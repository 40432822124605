<div class="container auth">
    <div class="row">
        <div class="col-md-4 col-md-offset-4">
            <form *ngIf="accessToken" name="resetForm" (submit)="setpassword($event)" #resetForm="ngForm">
                <div class="login-panel panel panel-default">
                    <div class="panel-heading">
                        <h3 class="panel-title">Reset password</h3>
                    </div>
                    <div class="panel-body">
                      <fieldset>
                        <div class="form-group">
                          <label for="password" class="rl-label">Senha</label>
                          <input form="resetForm" class="form-control" type="password" id="password" [(ngModel)]="password" (input)="validPassord()" (blur)="validPassord()" name="password" required autofocus>
                        </div>
                        <div class="form-group">
                          <label for="confirm" class="rl-label">Cofirmar</label>
                          <input form="resetForm" class="form-control" type="password" id="confirm" [(ngModel)]="confirm" (input)="validPassord()" (blur)="validPassord()" [ngClass]="passwordEqual ? 'ng-invalid' : 'ng-valid'" name="confirm" required>
                        </div>
                        <button type="submit" class="btn btn-md btn-success btn-block" [disabled]="!resetForm.form.valid && !passwordEqual">Salvar</button>
                      </fieldset>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
