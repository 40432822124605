
<div class="container">
  <mat-card>
    <mat-tab-group>
      <mat-tab label="Entrar">
        <app-login></app-login>
      </mat-tab>
      <mat-tab label="Criar Conta">
        <app-register></app-register>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</div>
