import { Injectable, Injector } from '@angular/core';
import { ToastService, AlertService, LoadingService, UtilsService } from 'app/shared/services/';
import { Title } from '@angular/platform-browser';
import { SidenavService } from './sidenav';
import { ThemeService } from '../theme';
import { DateServiceService } from './date/date.service';
import { GoogleAnalyticsEventsService } from './events-ga/google-analytics-events.service';
import { CookieService } from './cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class BaseService {
  public _toast: ToastService;
  public _loading: LoadingService;
  public _alert: AlertService;
  public _sideNav: SidenavService;
  public _theme: ThemeService;

  public date: DateServiceService;
  public analyticsEvents: GoogleAnalyticsEventsService;

  public _utils: UtilsService;
  public _titleService: Title;
  public _cookie: CookieService;

  public LOCAL_STORAGE = {
    ENDERECO: 'endereco',
  };

  constructor(injector: Injector) {
    this._toast = injector.get(ToastService);
    this._alert = injector.get(AlertService);
    this._loading = injector.get(LoadingService);
    this._cookie = injector.get(CookieService);
    this._utils = injector.get(UtilsService);
    this._theme = injector.get(ThemeService);
    this.analyticsEvents = injector.get(GoogleAnalyticsEventsService);
    this.date = injector.get(DateServiceService);

    this._titleService = injector.get(Title);
    this._sideNav = injector.get(SidenavService);
  }
}
