import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextMaskModule } from 'angular2-text-mask';
import { ToastrModule } from 'ngx-toastr';
import {
  AlertModule,
  GuidModule,
  LoadingModule,
  ToastModule,
  TranslateModule
} from 'app/shared/services';
import { SharedModule } from 'app/shared/shared.module';
import { LayoutModule } from 'app/layout/layout.module';

@NgModule({
  imports: [
    CommonModule,
    TextMaskModule,
    ToastrModule.forRoot(),
    AlertModule.forRoot(),
    GuidModule.forRoot(),
    LoadingModule.forRoot(),
    ToastModule.forRoot(),
    TranslateModule.forRoot(),
    SharedModule,
    LayoutModule

  ],
  exports: [
    LayoutModule,
    SharedModule
  ],
  declarations: []
})
export class CoreModule { }
