import { Usuario } from '@app/api';

export class UsuarioInput {
  id: string;
  nome: string;
  celular: string;
  telefone: string;
  email: string;

  constructor(input?: Partial<Usuario>) {
    this.id = input?.id;
    this.nome = input?.nome;
    this.celular = input?.celular;
    this.email = input?.email;
  }
}
