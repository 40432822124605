import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import 'dayjs/locale/pt-br'; // import locale

dayjs().format();
dayjs.locale('pt-br');

@Injectable({
  providedIn: 'root',
})
export class DateServiceService {
  public isAfter(inicio: Date, fim: Date): boolean {
    return dayjs(inicio).isAfter(fim);
  }

  public add({ intervalo, unidade, dataDeReferencia }: ManipularDataType): Date {
    return new Date(dayjs(dataDeReferencia).add(intervalo, unidade).format());
  }
}

class ManipularDataType {
  intervalo: number;
  unidade: dayjs.ManipulateType;
  dataDeReferencia?: Date = new Date();
}
