import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: '_currency',
})
export class CurrencyPipe implements PipeTransform {
  public transform(value: string, style?: string): any {
    if (!value) {
      return;
    }

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
    // console.log(new Intl.NumberFormat('pt-BR', { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2 }).format(100.99));
    const options = { style: 'decimal', currency: 'BRL', minimumFractionDigits: 2 };

    const pipe = 'R$ ' + new Intl.NumberFormat('pt-BR', options).format(+value);

    return pipe;
  }
}
