import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SDKToken } from '@app/api';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root',
})
export class SessaoService {
  constructor() {}

  public init(): void {
    let sessaoId = localStorage.getItem('sessaoId');


    if (!sessaoId) {
      localStorage.setItem('sessaoId', uuid());
    }
  }
}
