import { AfterViewInit, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { BaseService } from './shared/services/base';
import { MatSidenav } from '@angular/material/sidenav';
import { SessaoService } from './shared/services/sessao';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseService implements OnInit, AfterViewInit {
  public title = 'Tag2u - Compra e venda de usados';
  @ViewChild('sidenav') public drawer: MatSidenav;

  constructor(private _router: Router, private _injector: Injector, private _sessaoService: SessaoService) {
    super(_injector);
  }

  ngOnInit() {
    this.iniciarSessao();
    this._watchRoute();
    this.checkTheme();
  }

  ngAfterViewInit() {
    this._setSideNav();
  }

  private _watchRoute(): void {
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
  }

  private _setSideNav() {
    this._sideNav.setSidenav(this.drawer);
  }

  public toggleSideNav() {
    this._sideNav.toggle();
  }

  public iniciarSessao() {
    this._sessaoService.init();
  }

  public checkTheme(): void {
    if (localStorage.getItem('theme')) this._theme.setTheme(localStorage.getItem('theme'));
    else this._theme.setTheme('light');
  }
}
