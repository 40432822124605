<app-banner></app-banner>

<section class="padding-bottom-50">
  <div class="container">
    <div class="row">
      <home-slider-produtos
        [titulo]="'Usados em promoção'"
        [produtos]="produtoNovasPromocoes"
        [id_listagem]="'novas_promocoes_home'"
        [url]="'/produtos/promos'"
      ></home-slider-produtos>

      <app-quero-desocupar></app-quero-desocupar>

      <home-slider-produtos
        [titulo]="'Usados'"
        [produtos]="produtoRecemChegados"
        [id_listagem]="'novas_promocoes_home'"
        [url]="'/produtos/recem-chegados'"
      ></home-slider-produtos>

      <home-slider-produtos
        [titulo]="'Outlet'"
        [produtos]="produtosOutlet"
        [id_listagem]="'outlet_home'"
        [url]="'/produtos/outlet'"
      ></home-slider-produtos>

      <br />
      <br />
    </div>
  </div>
</section>
