import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class LoadingService {

    private _active = false;
    public status: Subject<boolean> = new Subject();

    constructor() {
    }

    public get active(): boolean {
        return this._active;
    }

    public set active(value: boolean) {
        this._active = value;
        this.status.next(value);
    }

    public start(): void {
        this.active = true;
    }

    public stop(): void {
        this.active = false;
    }

}
