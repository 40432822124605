import { Injectable } from '@angular/core';
import { Usuario, LoopBackFilter, Venda } from 'app/api/models/';
import { AuthService } from 'app/auth/auth.service';
import { UsuarioApi, EnderecoApi } from '@app/api';
import { Endereco } from '@app/api/models/Endereco';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  constructor(public authService: AuthService, private _enderecoApi: EnderecoApi, private _usuarioApi: UsuarioApi) {}

  public createEndereco(endereco: Endereco): Promise<Endereco> {
    return this._enderecoApi.upsert<Endereco>(endereco).toPromise();
  }

  public getEnderecoById(id: string): Promise<Endereco> {
    return this._enderecoApi.findById(id).toPromise() as Promise<Endereco>;
  }

  public deleteEnderecoById(id: string): Promise<Endereco> {
    return this._enderecoApi.deleteById(id).toPromise() as Promise<Endereco>;
  }

  public async getUsuarioById(id: string, filter?: LoopBackFilter): Promise<any> {
    return await new Promise((resolve, reject) => {
      this._usuarioApi
        .findById(id, filter)
        .toPromise()
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  }

  public get usuario(): Usuario {
    return this.authService.usuario;
  }

  public set usuario(value) {
    this.authService.usuario = value;
  }

  public get token() {
    return this.authService.usuarioToken;
  }

  public getUsuario(filter?: LoopBackFilter): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.usuarioApi
        .findById(this.usuario.id, filter)
        .toPromise()
        .then((res) => {
          this.usuario = res as any;
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  public async upsertUsuario(usuario: Usuario): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.authService.usuarioApi
        .patchAttributes(this.usuario.id, usuario)
        .toPromise()
        .then((res) => {
          this.usuario = res;
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  }

  public async getUsuarioComCompras(): Promise<Venda[]> {
    const { compras } = await this.authService.usuarioApi.getUsuarioComCompras(this.authService.usuario.id).toPromise();

    return compras;
  }

  public validaCPF(strCPF: string): boolean {
    let soma = 0;
    let resto;

    if (strCPF === '' || strCPF === undefined) return false;

    if (strCPF === '00000000000') return false;

    strCPF = strCPF.replace(/\./g, '').replace(/-/, '');

    for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(strCPF.substring(9, 10))) return false;

    soma = 0;

    for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(strCPF.substring(10, 11))) return false;

    return true;
  }

  public async checkCPF(cpf: string, usuarioId: string = '0'): Promise<any> {
    return new Promise((resolve, reject) => {
      this._usuarioApi.checkCPF(cpf, usuarioId).subscribe(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  public async checkEmail(email: string, usuarioId: string = '0'): Promise<any> {
    return new Promise((resolve, reject) => {
      this._usuarioApi.checkEmail(email, usuarioId).subscribe(
        (result) => resolve(result.email),
        (error) => reject(error)
      );
    });
  }

  public async checkCelular(celular: string, usuarioId: string = '0'): Promise<any> {
    return new Promise((resolve, reject) => {
      this._usuarioApi.checkCelular(celular, usuarioId).subscribe(
        (res) => {
          resolve(res.celular);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public async createUsuario(usuario: Usuario): Promise<void> {
    return await new Promise((resolve, reject) => {
      this._usuarioApi
        .create(usuario)
        .toPromise()
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  }
}
