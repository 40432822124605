import { gql } from 'apollo-angular';
import { GetFaq, Faq } from './faq.model';


export const FAQ_QUERY = gql`
query faq($pagination: PaginationInput, $where: GetFaqInput){
    faq(pagination: $pagination, where: $where) {
        id
        pergunta
        resposta
    }
}
`;

export interface FaqQueryResponse {
    faq: Faq[];
    loading: boolean;
}

export const GET_FAQ_BY_ID = gql`
query GetFaqById($id: String!) {
    getFaqById(id: $id) {
        id
        createdAt
        updatedAt
        pergunta
        resposta
    }
}
`;

export interface GetFaqByQueryResponse {
    faq: Faq[];
    loading: boolean;
}

export const FAQ_COUNT = gql`
query quantidadeFaq($where: GetFaqInput){
    quantidadeFaq(where: $where)
}
`;

export interface quantidadeFaqQueryResult {
    quantidadeFaq: number;
}

export interface GetFaqByQueryResponse {
    getFaqById: Faq;
    loading: boolean;
}