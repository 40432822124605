import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'busca', loadChildren: () => import('./pages/busca/busca.module').then((m) => m.BuscaModule) },
  { path: 'busca-alocados', loadChildren: () => import('./pages/busca-alocados/busca-alocados.module').then((m) => m.BuscaAlocadosModule) },
  { path: 'hub', loadChildren: () => import('./pages/produtos-hub/produtos-hub.module').then((m) => m.ProdutosHubModule) },
  { path: 'black-friday', redirectTo: 'campanha/black-week' },
  { path: 'campanha', loadChildren: () => import('./pages/campanha/campanha.module').then((m) => m.CampanhaModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', initialNavigation: 'enabledBlocking' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
