<section>
  <h1 class="text-center">{{ titulo }}</h1>

  <owl-carousel-o [options]="carrouselProdutos" *ngIf="produtos?.length > 0">
    <ng-template carouselSlide *ngFor="let produto of produtos" class="custom-grid">
      <a (click)="processarEventoDeClick(produto)" [routerLink]="['/produto/', produto.url]">
        <card-produto [produto]="produto"></card-produto>
      </a>
    </ng-template>
  </owl-carousel-o>

  <ng-container *ngIf="!produtos ?? produtos?.length == 0">
    <owl-carousel-o [options]="carrouselProdutos">
      <ng-template carouselSlide *ngFor="let produto of [1, 2, 3, 4]" class="custom-grid">
        <app-card-shimmer></app-card-shimmer>
      </ng-template>
    </owl-carousel-o>
  </ng-container>

  <br />

  <div class="text-center">
    <button mat-raised-button color="primary" [routerLink]="url">Ver mais</button>
  </div>
</section>
