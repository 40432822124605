export const environment = {
  production: true,
  API_URL: 'https://api.tag2u.com.br',
  GRAPHQL_URL: 'https://graphql.tag2u.com.br/graphql',
  API_VERSION: 'api',
  API_DEBUG: true,
  VENDEDOR_URL: 'https://tag2u.com.br',
  TELEFONES: {
    COMERCIAL: '5511991965700',
    EXPEDICAO: '5511945424043',
  },
  firebase: {
    apiKey: 'AIzaSyDqLbD5OtBWwck_9oYz4ixR6GxrMGk5sKk',
    authDomain: 'tag2u-push.firebaseapp.com',
    projectId: 'tag2u-push',
    storageBucket: 'tag2u-push.appspot.com',
    messagingSenderId: '469638138429',
    appId: '1:469638138429:web:babaee476bf8636f13f854',
    measurementId: 'G-L0VT3DXKG7',
  },
  AWS: {
    accessKeyId: 'AKIAY4IC5FX2XDVTNUS3',
    bucketName: 'tag2u',
    region: 'sa-east-1',
    secretAccessKey: '862IJZk1gerpIf1m3DLe3eoUdqjoKtDcQ6lfRDxo',
    url: 'https://tag2u.s3-sa-east-1.amazonaws.com/',
  },
  TRANSPORTADOR_NEZTI: '7ad51a44-d437-46e7-bf43-ff43b03be6b0',
};
