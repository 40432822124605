import { ErrorHandler } from './services/core/error.service';
import { LoopBackAuth } from './services/core/auth.service';
import { LoggerService } from './services/custom/logger.service';
import { SDKModels } from './services/custom/SDKModels';
import { InternalStorage, SDKStorage } from './storage/storage.swaps';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CookieBrowser } from './storage/cookie.browser';
import { StorageBrowser } from './storage/storage.browser';
import { SolicitacaoServicoApi } from './services/custom/SolicitacaoServico';
import { TransacaoApi } from './services/custom/Transacao';
import { UsuarioApi } from './services/custom/Usuario';
import { HubApi } from './services/custom/Hub';
import { ProdutoApi } from './services/custom/Produto';
import { VendaApi } from './services/custom/Venda';
import { VendaItemApi } from './services/custom/VendaItem';
import { CarteiraApi } from './services/custom/Carteira';
import { ProdutoFotosApi } from './services/custom/ProdutoFotos';
import { CategoriaApi } from './services/custom/Categoria';
import { PagamentoApi } from './services/custom/Pagamento';
import { EnderecoApi } from './services/custom/Endereco';
import { DadosBancarioApi } from './services/custom/DadosBancario';
import { MarcaApi } from './services/custom/Marca';
import { SecaoApi } from './services/custom/Secao';
import { SecoesEProdutosApi } from './services/custom/SecoesEProdutos';
/**
 * @module SDKBrowserModule
 * @description
 * This module should be imported when building a Web Application in the following scenarios:
 *
 *  1.- Regular web application
 *  2.- Angular universal application (Browser Portion)
 *  3.- Progressive applications (Angular Mobile, Ionic, WebViews, etc)
 **/
@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  exports: [],
  providers: [ErrorHandler],
})
export class SDKBrowserModule {
  static forRoot(
    internalStorageProvider: any = {
      provide: InternalStorage,
      useClass: CookieBrowser,
    }
  ): ModuleWithProviders<any> {
    return {
      ngModule: SDKBrowserModule,
      providers: [
        LoopBackAuth,
        LoggerService,
        SDKModels,
        SolicitacaoServicoApi,
        TransacaoApi,
        UsuarioApi,
        HubApi,
        ProdutoApi,
        VendaApi,
        VendaItemApi,
        CarteiraApi,
        ProdutoFotosApi,
        CategoriaApi,
        PagamentoApi,
        EnderecoApi,
        DadosBancarioApi,
        MarcaApi,
        SecaoApi,
        SecoesEProdutosApi,
        internalStorageProvider,
        { provide: SDKStorage, useClass: StorageBrowser },
      ],
    };
  }
}
/**
 * Have Fun!!!
 * - Jon
 **/
export * from './models/index';
export * from './services/index';
export * from './lb.config';
export * from './storage/storage.swaps';
export { CookieBrowser } from './storage/cookie.browser';
export { StorageBrowser } from './storage/storage.browser';
