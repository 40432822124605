import { NgModule } from '@angular/core';

import { DatePipe } from './date';
import { CurrencyPipe } from './currency';

@NgModule({
    declarations: [
        DatePipe,
        CurrencyPipe
    ],
    exports: [
        DatePipe,
        CurrencyPipe
    ]
})

export class PipesModule {

    constructor() {

    }

}
