import { BaseService } from 'app/shared/services/base';
import { Component, OnInit, Injector } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'app/data';
import { AuthService } from 'app/auth/auth.service';
import { Usuario } from 'app/api/';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends BaseService implements OnInit {
  public error = true;
  public returnUrl: string;

  public loginForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required]),
    password: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    private _base: Injector,
    private usuarioService: UsuarioService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    super(_base);
  }

  public ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/conta/dashboard';
    this.usuarioService.authService.logout();
  }

  public async login() {
    this._loading.start();
    await this.authService
      .login({ ...this.loginForm.value })
      .then(() => {
        this._toast.success('Bem vindo!');
        this.router.navigateByUrl(this.returnUrl);
      })
      .catch((error) => {
        this._alert.error('Erro', error.message);
      })
      .finally(() => {
        this._loading.stop();
      });
  }
}
