import { BaseService } from 'app/shared/services/base';
import { Component, Injector, OnInit } from '@angular/core';
import { Usuario } from 'app/api/models';
import { Router } from '@angular/router';
import { ContactsEnum } from '@app/shared/enumerators/contacts.enum';
import { UsuarioService } from '@app/data/usuario/usuario.service';
import { CUSTOM_MASKS } from '@app/shared/services/masks';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent extends BaseService implements OnInit {
  public usuario: Usuario = new Usuario();
  public masks = CUSTOM_MASKS.masks;
  public formularioVisivel: boolean = false;
  public confirm: string;
  public returnUrl: string;
  public contactType: number = ContactsEnum.MOBILE;
  public contactsEnum = ContactsEnum;

  constructor(private _base: Injector, public usuarioService: UsuarioService, private _router: Router) {
    super(_base);
  }

  ngOnInit() {
    this.usuario.tipoUsuario = 'COMPRADOR';
  }

  public exibirForm() {
    this.formularioVisivel = true;
  }

  public ocultarForm() {
    this.formularioVisivel = false;
  }

  public get tipoContato() {
    const tipos = ['Celular (Br)', 'Celular (US)', 'Celular (outros)'];

    return tipos[this.contactType];
  }

  public setContactType(contactType: number): void {
    this.contactType = contactType;

    this.usuario.celular = undefined;
  }

  public async register(e): Promise<any> {
    e.preventDefault();
    if (this.confirm !== this.usuario.password) {
      this._alert.error('Opa!', `Senhas não coincidem.`);

      return;
    }

    this._loading.start();
    await this.usuarioService.authService
      .register(this.usuario)
      .then(() => {
        this._toast.success('Yes!!', 'Conta criada com sucesso!');
        this._toast.success('Redirecionando....');
        this.usuarioService.authService.login(this.usuario).then(() => {
          this._loading.stop();
          this._router.navigateByUrl('/conta/dashboard');
        });
      })
      .catch((error: any) => {
        if (error.statusCode === 422) {
          this._alert.error('Opa!', 'Esse email já existe em nossa base; Problemas com a senha?');
        } else {
          this._alert.error('Opa!', `Ocorreu um erro ao criar o usuário: ${error.message}`);
        }
        this._loading.stop();
      });
  }
}
