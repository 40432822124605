<footer id="footer" class="sticky-footer">
  <div class="container">
    <div class="row">
      <section class="col-md-4 col-xs-12 col-sm-12">
        <h2>Utilidades</h2>
        <a class="link-footer" routerLink="/sobre">
          <h4>Sobre</h4>
        </a>

        <a class="link-footer" routerLink="/politica-reembolso">
          <h4>Política de Troca e Devolução</h4>
        </a>

        <a class="link-footer" routerLink="/contato">
          <h4>Contato e localização</h4>
        </a>

        <a class="btn-vender" routerLink="/quero-vender">
          <h4>Quero Vender</h4>
        </a>

        <a class="link-footer" routerLink="/">
          <h4>Quero Comprar</h4>
        </a>
        <a class="link-footer" routerLink="/carreiras">
          <h4>Carreiras</h4>
        </a>
        <a class="link-footer" routerLink="/faq">
          <h4>Ajuda</h4>
        </a>
        <a class="link-footer" routerLink="/termos-de-uso">
          <h4>Termos de uso</h4>
        </a>
      </section>

      <section class="col-md-4 col-xs-12 col-sm-12">
        <h2>Meu perfil</h2>
        <a [href]="vendedorUrl + '/conta/meus-produtos'" class="link-footer">
          <h4>Meus produtos</h4>
        </a>
        <a [href]="vendedorUrl + '/conta/carteira'" class="link-footer">
          <h4>Sacar</h4>
        </a>
        <a href="https://conta.tag2u.com.br/" target="_blank" class="link-footer">
          <h4>Já sou um parceiro</h4>
        </a>
      </section>

      <section class="col-md-4 col-xs-12 col-sm-12">
        <h2>Outros</h2>
        <a href="https://www.facebook.com/tag2uloja/" target="_blank" class="link-footer">
          <h4>Facebook</h4>
        </a>
        <a href="https://www.instagram.com/tag2uloja/" target="_blank" class="link-footer">
          <h4>Instagram</h4>
        </a>
        <a class="link-footer" routerLink="/trabalhe-conosco">
          <h4>Trabalhe conosco</h4>
        </a>
      </section>
    </div>

    <!-- Copyright -->
    <section class="row">
      <div class="col-md-12">
        <div class="copyrights">
          © {{ year }} Tag2u. Todos os direitos reservados. <br>
          Visite nosso showroom: Segunda a Sábado: 9:00 às 18:00 <br>
          Rua Gago Coutinho, 268, Lapa, São Paulo - SP <br>
          <a class="wpp" [href]="link">
            WhatsApp: (11) 99196-5700
          </a>
        </div>
      </div>
    </section>
  </div>
</footer>

<div id="backtotop">
  <a href="#"></a>
</div>
