import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { LayoutModule } from '@app/layout/layout.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatButtonModule } from '@angular/material/button';
import { HomeService } from './home.service';
import { BannerComponent } from './banner/banner.component';
import { BlackWeekModule } from '../black-week/black-week.module';
import { HomeSliderProdutosComponent } from './home-slider-produtos/home-slider-produtos.component';
import { CardShimmerComponent } from '@app/layout/card-shimmer/card-shimmer.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [HomeComponent, BannerComponent, HomeSliderProdutosComponent],
  imports: [CommonModule, MatButtonModule, LayoutModule, HomeRoutingModule, CarouselModule, BlackWeekModule, CardShimmerComponent],
  providers: [HomeService],
  exports: [BannerComponent, HomeSliderProdutosComponent],
})
export class HomeModule {}
