import { Injectable } from '@angular/core';

import swal, { SweetAlertResult } from 'sweetalert2';
import { SweetAlertOptions } from 'sweetalert2';
import { SweetAlertType } from 'sweetalert2';

@Injectable()
export class AlertService {
  private defaultOptions = {
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonClass: 'btn btn-success',
    cancelButtonClass: 'btn btn-danger',
  };

  constructor() {}

  // Adiciona o defaultOption, options e outras infos em runtime
  // Object.assign(defaultOptions, options, { type: 'warning' })

  /**
   * confirm
   *
   * @param {string} title
   * @param {string} text
   * @param {string} textConfirm
   * @memberof AlertService
   */
  public confirm(title: string, text: string, textConfirm: string): Promise<any> {
    const type: SweetAlertType = 'question';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
      showCancelButton: true,
      confirmButtonText: textConfirm,
    };

    return swal({ ...this.defaultOptions, ...options });
  }

  /**
   * prompt
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public prompt(title: string, text: string, inputType: SweetAlertOptions['input']): Promise<any> {
    const type: SweetAlertType = 'question';
    const input: SweetAlertOptions['input'] = inputType;
    const options: SweetAlertOptions = {
      type,
      title,
      text,
      input,
      showCancelButton: true,
    };

    return swal({ ...this.defaultOptions, ...options });
  }

  /**
   * promptText
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public promptText(title: string, text: string): Promise<any> {
    const type: SweetAlertType = 'question';
    const input: SweetAlertOptions['input'] = 'text';
    return this.prompt(title, text, input);
  }

  /**
   * promptEmail
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public promptEmail(title: string, text: string) {
    const type: SweetAlertType = 'question';
    const input: SweetAlertOptions['input'] = 'email';
    this.prompt(title, text, input);
  }

  /**
   * promptPassword
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public promptPassword(title: string, text: string) {
    const type: SweetAlertType = 'question';
    const input: SweetAlertOptions['input'] = 'password';
    this.prompt(title, text, input);
  }

  // number
  // tel
  // range
  // textarea
  // select
  // radio
  // checkbox
  // file
  // url

  /**
   * question
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public question(title: string, text: string): Promise<any> {
    const type: SweetAlertType = 'question';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
      showCancelButton: true,
    };

    return swal({ ...this.defaultOptions, ...options });
  }

  /**
   * alert
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public alert(title: string, text: string): Promise<any> {
    const type: SweetAlertType = 'warning';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
    };

    return swal({ ...this.defaultOptions, ...options });
  }

  /**
   * success
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public success(title: string, text: string): void {
    const type: SweetAlertType = 'success';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
    };
    swal({ ...this.defaultOptions, ...options });
  }

  /**
   * success
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public successPromise(title: string, text: string): Promise<SweetAlertResult> {
    const type: SweetAlertType = 'success';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
    };
    return swal({ ...this.defaultOptions, ...options });
  }

  /**
   * warning
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public warning(title: string, text: string): void {
    const type: SweetAlertType = 'warning';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
    };
    swal({ ...this.defaultOptions, ...options });
  }

  /**
   * error
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public error(title: string, text: string): void {
    const type: SweetAlertType = 'error';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
    };
    swal({ ...this.defaultOptions, ...options });
  }

  /**
   * info
   *
   * @param {string} title
   * @param {string} text
   * @memberof AlertService
   */
  public info(title: string, text: string): void {
    const type: SweetAlertType = 'info';
    const options: SweetAlertOptions = {
      type,
      title,
      text,
    };
    swal({ ...this.defaultOptions, ...options });
  }
}
