import { gql } from 'apollo-angular';
import { DocumentNode } from 'graphql';

export const PRODUTOS_CARD_FRAGMENT: DocumentNode = gql`
  fragment produtoFragment on Produto {
    codProduto
    compraTag
    dropShipping
    estado
    hubId
    id
    novo
    operacaoPropria
    quantidadeDisponivel
    reversa
    status
    tituloComposto
    url
    valorB2B
    valorNovoCheio
    valorinicial
    valorsite
    foto {
      url
    }
    campanhasAtivas {
      ativa
      id
      oferecerDesconto
      tituloCampanha
      tipoDesconto
      valorDesconto
    }
  }
`;

export const PRODUTOS_BOTA_FORA = gql`
  query produtosBotaForaLojaPaginado($take: Float, $skip: Float) {
    query: produtosBotaForaPaginado(pagination: { take: $take, skip: $skip }) {
      dados {
        ...produtoFragment
      }
      quantidade
    }
  }

  ${PRODUTOS_CARD_FRAGMENT}
`;

export const PRODUTOS_QUEIMA_ESTOQUE = gql`
  query produtosQueimaDeEstoqueLojaPaginado($take: Float, $skip: Float) {
    query: produtosQueimaDeEstoquePaginado(pagination: { take: $take, skip: $skip }) {
      dados {
        ...produtoFragment
      }
      quantidade
    }
  }

  ${PRODUTOS_CARD_FRAGMENT}
`;

export const PRODUTOS_RECEM_CHEGADOS = gql`
  query produtosRecemChegadosLojaPaginado($take: Float, $skip: Float) {
    query: produtosRecemChegadosPaginado(pagination: { take: $take, skip: $skip }) {
      dados {
        ...produtoFragment
      }
      quantidade
    }
  }
  ${PRODUTOS_CARD_FRAGMENT}
`;

export const PRODUTOS_NOVAS_PROMOS = gql`
  query produtosNovasPromosLojaPaginado($take: Float, $skip: Float) {
    query: produtosNovasPromocoesPaginado(pagination: { take: $take, skip: $skip }) {
      dados {
        ...produtoFragment
      }
      quantidade
    }
  }

  ${PRODUTOS_CARD_FRAGMENT}
`;

export const PRODUTOS_EM_ALTA = gql`
  query produtosEmAltaLoja($take: Float, $skip: Float) {
    query: produtosEmAlta(pagination: { take: $take, skip: $skip }) {
      ...produtoFragment
      viewsCount
    }
  }

  ${PRODUTOS_CARD_FRAGMENT}
`;

export const PRODUTOS_PRE_VENDA = gql`
  query produtosPreVendaLoja($take: Float, $skip: Float) {
    query: produtosPreVenda(pagination: { take: $take, skip: $skip }) {
      dados {
        ...produtoFragment
      }
      quantidade
    }
  }

  ${PRODUTOS_CARD_FRAGMENT}
`;

export const PRODUTOS_OUTLET = gql`
  query produtosOutlet($take: Float, $skip: Float) {
    query: produtosOutlet(pagination: { take: $take, skip: $skip }) {
      dados {
        ...produtoFragment
      }
      quantidade
    }
  }

  ${PRODUTOS_CARD_FRAGMENT}
`;
