import { Inject, Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SDKToken } from 'app/api/models/BaseModels';
import { LoopBackAuth } from 'app/api/services/';
import { BehaviorSubject, Observable } from 'rxjs';
import { UsuarioApi } from 'app/api/services/custom/Usuario';
import { Usuario } from 'app/api/models/Usuario';
import { HttpClient } from '@angular/common/http';
import axios from 'axios';
import { Apollo, gql } from 'apollo-angular';

@Injectable()
export class AuthService {
  constructor(
    private _authApi: LoopBackAuth,
    public usuarioApi: UsuarioApi,
    @Inject(LoopBackAuth) protected _loopbackAuth: LoopBackAuth,
    private _apollo: Apollo
  ) {}

  public async login(usuario: Usuario): Promise<void> {
    const mutation = gql`
      mutation loginAdmin($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          id
          ttl
          scopes
          created
          userId
          user {
            id
            nome
            celular
            cpf
            cnpj
            email
          }
        }
      }
    `;

    const authData = await this._apollo
      .mutate<Login>({
        mutation,
        variables: {
          email: usuario.email,
          password: usuario.password,
        },
      })
      .toPromise();

    this._loopbackAuth.setToken({ ...authData.data.login, rememberMe: true });
  }

  public get usuarioComCompras(): Observable<Usuario> {
    return this.usuarioApi.findById(this.usuario.id, {
      include: 'compras',
    });
  }

  public get usuario(): Usuario {
    return this._authApi.getCurrentUserData();
  }

  public set usuario(value) {
    this._authApi.setUser(value);
  }

  public get usuarioToken(): string {
    return this._authApi.getAccessTokenId();
  }

  public register(usuario: Usuario): Promise<any> {
    return this.usuarioApi.create(usuario).toPromise();
  }

  public logout() {
    this.usuarioApi.logout();
  }

  public async reset(email: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.usuarioApi
        .resetPassword({ email })
        .toPromise()
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async setPassword(newPassword: string, token?: SDKToken): Promise<any> {
    return await new Promise((resolve, reject) => {
      const url = `${environment.API_URL}/${environment.API_VERSION}/usuarios/reset-password`;

      const body = JSON.stringify({
        newPassword,
      });

      axios
        .post(url, body, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: token.toString(),
          },
        })
        .then((result) => resolve(result))
        .catch((error) => reject(error));
    });
  }
  public async changePassword(oldPassword: string, newPassword: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.usuarioApi
        .changePassword(oldPassword, newPassword)
        .toPromise()
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

interface Login {
  login: SDKToken;
}
