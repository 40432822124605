/* tslint:disable */

declare var Object: any;
export interface UsuarioInterface {
  id?: string;
  tipo?: string;
  codigoOmie?: number;
  nome?: string;
  cpf?: string;
  cnpj?: string;
  rg?: string;
  dataNascimento?: string;
  sexo?: string;
  telefoneResidencial?: string;
  telefoneComercial?: string;
  celular?: string;
  whatsapp?: string;
  ativo?: boolean;
  created?: Date;
  estadoCivil?: string;
  tipoUsuario?: string;
  apelido?: string;
  hasToResetPassword?: boolean;
  ativoFeito?: boolean;
  origemLead?: string;
  sobrenome?: string;
  realm?: string;
  username?: string;
  email: string;
  emailVerified?: boolean;
  createdAt: Date;
  updatedAt: Date;
  password?: string;
  accessTokens?: any[];
  role?: any;
  solicitacoes?: any[];
  produtos?: any[];
  produtosVendidos?: any[];
  compras?: any[];
  carteira?: any;
  enderecos?: any[];
  dadosBancarios?: any[];
}

export class Usuario implements UsuarioInterface {
  'id': string;
  'tipo': string;
  'codigoOmie': number;
  'nome': string;
  'cpf': string;
  'cnpj': string;
  'rg': string;
  'dataNascimento': string;
  'sexo': string;
  'telefoneResidencial': string;
  'telefoneComercial': string;
  'celular': string;
  'whatsapp': string;
  'ativo': boolean;
  'created': Date;
  'estadoCivil': string;
  'tipoUsuario': string;
  'apelido': string;
  'hasToResetPassword': boolean;
  'ativoFeito': boolean;
  'origemLead': string;
  'sobrenome': string;
  'realm': string;
  'username': string;
  'email': string;
  'emailVerified': boolean;
  'createdAt': Date;
  'updatedAt': Date;
  'password': string;
  accessTokens: any[];
  role: any;
  solicitacoes: any[];
  produtos: any[];
  produtosVendidos: any[];
  compras: any[];
  carteira: any;
  enderecos: any[];
  dadosBancarios: any[];
  constructor(data?: UsuarioInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Usuario`.
   */
  public static getModelName() {
    return 'Usuario';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of Usuario for dynamic purposes.
   **/
  public static factory(data: UsuarioInterface): Usuario {
    return new Usuario(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'Usuario',
      plural: 'Usuarios',
      path: 'Usuarios',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        tipo: {
          name: 'tipo',
          type: 'string',
        },
        codigoOmie: {
          name: 'codigoOmie',
          type: 'number',
        },
        nome: {
          name: 'nome',
          type: 'string',
        },
        cpf: {
          name: 'cpf',
          type: 'string',
        },
        cnpj: {
          name: 'cnpj',
          type: 'string',
        },
        rg: {
          name: 'rg',
          type: 'string',
        },
        dataNascimento: {
          name: 'dataNascimento',
          type: 'string',
        },
        sexo: {
          name: 'sexo',
          type: 'string',
        },
        telefoneResidencial: {
          name: 'telefoneResidencial',
          type: 'string',
        },
        telefoneComercial: {
          name: 'telefoneComercial',
          type: 'string',
        },
        celular: {
          name: 'celular',
          type: 'string',
        },
        whatsapp: {
          name: 'whatsapp',
          type: 'string',
        },
        ativo: {
          name: 'ativo',
          type: 'boolean',
          default: true,
        },
        created: {
          name: 'created',
          type: 'Date',
        },
        estadoCivil: {
          name: 'estadoCivil',
          type: 'string',
        },
        tipoUsuario: {
          name: 'tipoUsuario',
          type: 'string',
        },
        apelido: {
          name: 'apelido',
          type: 'string',
        },
        hasToResetPassword: {
          name: 'hasToResetPassword',
          type: 'boolean',
          default: false,
        },
        ativoFeito: {
          name: 'ativoFeito',
          type: 'boolean',
          default: false,
        },
        origemLead: {
          name: 'origemLead',
          type: 'string',
        },
        sobrenome: {
          name: 'sobrenome',
          type: 'string',
        },
        realm: {
          name: 'realm',
          type: 'string',
        },
        username: {
          name: 'username',
          type: 'string',
        },
        email: {
          name: 'email',
          type: 'string',
        },
        emailVerified: {
          name: 'emailVerified',
          type: 'boolean',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
        password: {
          name: 'password',
          type: 'string',
        },
      },
      relations: {
        accessTokens: {
          name: 'accessTokens',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'userId',
        },
        role: {
          name: 'role',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'principalId',
        },
        solicitacoes: {
          name: 'solicitacoes',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'usuarioId',
        },
        produtos: {
          name: 'produtos',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'usuarioId',
        },
        produtosVendidos: {
          name: 'produtosVendidos',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'antigoProprietarioId',
        },
        compras: {
          name: 'compras',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'usuarioId',
        },
        carteira: {
          name: 'carteira',
          type: 'any',
          model: '',
          relationType: 'hasOne',
          keyFrom: 'id',
          keyTo: 'usuarioId',
        },
        enderecos: {
          name: 'enderecos',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'usuarioId',
        },
        dadosBancarios: {
          name: 'dadosBancarios',
          type: 'any[]',
          model: '',
          relationType: 'hasMany',
          keyFrom: 'id',
          keyTo: 'usuarioId',
        },
      },
    };
  }
}
