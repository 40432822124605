<div class="container">
  <a routerLink="/campanha/black-week" *ngIf="isBlackWeek">
    <img src="assets/images/banner-black-week-web.jpg" class="hide-on-mobile" alt="banner black week" />

    <img src="assets/images/banner-black-week-mobile.jpg" class="show-on-mobile-only" alt="banner black week" />
  </a>

  <div *ngIf="!isBlackWeek">
    <img src="assets/images/banner-black-week-web.jpg" class="hide-on-mobile" alt="banner black week" />

    <img src="assets/images/banner-black-week-mobile.jpg" class="show-on-mobile-only" alt="banner black week" />
  </div>
  <h2 spellcheck="false" id="counter" *ngIf="!isBlackWeek"></h2>
</div>
