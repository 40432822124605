import { Component, OnInit } from '@angular/core';
import { BaseService } from '@app/shared/services/base';

@Component({
  selector: 'app-black-week',
  templateUrl: './black-week.component.html',
  styleUrls: ['./black-week.component.scss'],
})
export class BlackWeekComponent implements OnInit {
  constructor(private readonly _base: BaseService) {}

  public isBlackWeek: boolean = this._base.date.isAfter(new Date(), new Date('11/20/2021 09:00'));

  ngOnInit(): void {
    var countDownDate = new Date('Nov 20, 2021 09:00:00').getTime();

    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);

      document.getElementById('counter').innerHTML = days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ';
      if (distance < 0) {
        clearInterval(x);
        document.getElementById('counter').innerHTML = 'BLACK WEEK';
        this._router.navigateByUrl('/campanha/black-week');
      }
    }, 1000);
  }
}
