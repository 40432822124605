import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-register-login',
    templateUrl: './register-login.component.html',
    styleUrls: ['./register-login.component.scss']
})
export class RegisterLoginComponent implements OnInit {

    showLogin = true;

    constructor() { }

    ngOnInit(): void { }

    public toggleView(): void {
        this.showLogin = !this.showLogin;
    }
}
