import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { Campanha, TiposDescontoCampanha } from '@app/data/campanha';
import { Produto } from '@app/models';

@Component({
  selector: 'card-produto',
  templateUrl: './card-produto.component.html',
  styleUrls: ['./card-produto.component.scss'],
})
export class CardProduto implements OnInit, AfterViewInit {
  @Input() produto: any;
  @Input() navigateFunction: any;
  @Input() customClass: string;
  @Input() produtoB2B: boolean;
  @ViewChild('cardImage') cardImage: ElementRef;

  public desconto: number;
  public valorComDescontoCampanha: number;
  public campanha: Campanha;
  public url: string;

  constructor() {}

  ngOnInit() {
    this._calcularDescontosDasCampanhas();
    this._calcularDescontoReversa();
    this._verificarLocalStorage();
  }

  ngAfterViewInit(): void {
    this.cardImage?.nativeElement?.insertAdjacentHTML(
      'beforeend',
      `<amp-img height="180" width="180" layout="responsive" itemprop="image" src="${this.produto?.foto?.url}">
        <amp-img
          alt="Imagem destorcida - conteúdo não carregado"
          fallback
          height="180"
          width="180"
          layout="responsive"
          src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSAiaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmlld0JveD0iMSAxIDggNCI+PGltYWdlIGZpbHRlcj0idXJsKCNibHVyKSIgd2lkdGg9IjEwIiBoZWlnaHQ9IjYiIHhsaW5rOmhyZWY9ImRhdGE6aW1hZ2UvcG5nO2Jhc2U2NCxpVkJPUncwS0dnb0FBQUFOU1VoRVVnQUFBQW9BQUFBR0NBWUFBQUQ2OEEvR0FBQUFBWE5TUjBJQXJzNGM2UUFBQUVSbFdFbG1UVTBBS2dBQUFBZ0FBWWRwQUFRQUFBQUJBQUFBR2dBQUFBQUFBNkFCQUFNQUFBQUJBQUVBQUtBQ0FBUUFBQUFCQUFBQUNxQURBQVFBQUFBQkFBQUFCZ0FBQUFEK2lGWDBBQUFBcjBsRVFWUUlIVDJQU1FxRlFBeEVYN2VpNG9BYkVRV3Y0TVlMZUhLdjQwSVV3UW5uL25ZdmZxQ29JcWxRaVVpU1JQbStqNVFTei9PNDd4dkhjUmlHZ2I3dnNTeUw2N3FRNy91YW9XNHN5L0kzYXYwOGp6SHlsZFNHZmQrcDY5cXcxbTNia21VWlFnalQweXlpS0ZKNlE4ZVdaVWxSRklSaGFLS2JwdUU4VCtaNVJyaXVxejZna2VjNVZWVXhqaVBUTkpremp1TmcyemJzN3htQ0lDQk5VK0k0cHVzNmxGS3M2NHB0MjBicnhCODZ4VmdkUHdJV2NRQUFBQUJKUlU1RXJrSmdnZz09Ii8+PGZpbHRlciBpZD0iYmx1ciI+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iLjUiIC8+PC9maWx0ZXI+PC9zdmc+"
        >
        </amp-img>
      </amp-img>`
    );
  }

  private _verificarLocalStorage(): void {
    if (!this.produtoB2B) {
      this.produtoB2B = !!localStorage.getItem('clienteB2B');
    }
  }

  private _calcularDescontosDasCampanhas(): void {
    if (this.produto?.campanhasAtivas?.length > 0) {
      this.campanha = new Campanha(this.produto.campanhasAtivas.find(({ oferecerDesconto }) => oferecerDesconto) || this.produto.campanhasAtivas[0]);

      if (!this.campanha?.id) {
        this.valorComDescontoCampanha = Number(this.produto.valorsite);
        return;
      }

      let valorDescontoEmReais = 0;

      if (this.campanha.tipoDesconto === TiposDescontoCampanha.PERCENTUAL) {
        valorDescontoEmReais = (this.produto.valorsite * this.campanha.valorDesconto) / 100;
      } else {
        valorDescontoEmReais = this.campanha.valorDesconto;
      }

      this.valorComDescontoCampanha = Number(this.produto.valorsite - valorDescontoEmReais);
    }
  }

  private _calcularDescontoReversa(): void {
    if (this.produto?.reversa || this.produto?.novo) {
      this.desconto = Number((((this.produto.valorNovoCheio - this.produto.valorsite) * 100) / this.produto.valorNovoCheio).toFixed(0));
    } else {
      this.desconto = Number((((this.produto.valorinicial - this.produto.valorsite) * 100) / this.produto.valorinicial).toFixed(0));
    }
  }

  textoCategoria(produto: Produto) {
    if (produto.novo || produto.reversa) return 'Outlet';
    return 'Usado';
  }
}
