<mat-toolbar class="mat-elevation-z4">
  <button mat-icon-button aria-label="abrir menu" class="show-on-mobile-only" (click)="toggleSideNav()">
    <mat-icon aria-hidden="true">menu</mat-icon>
  </button>
  <span class="spacer show-on-mobile-only"></span>

  <a routerLink="/" class="logo-header">
    <amp-img height="65" width="120" src="https://cdn.tag2u.com.br/logos/tag2u-logo.webp" alt="Logo tag2u"></amp-img>
  </a>

  <mat-form-field appearance="outline" class="hide-on-mobile">
    <mat-label>Pesquisar...</mat-label>
    <input matInput type="text" [(ngModel)]="search" name="pesquisar" (keyup.enter)="goToSeachPage()" />
    <button matSuffix mat-icon-button aria-label="Pesquisar" (click)="goToSeachPage()" color="primary" type="button">
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <span class="spacer"></span>

  <div class="hide-on-mobile">
    <button mat-button color="primary" routerLink="/quero-vender">Quero vender</button>

    <button mat-button color="primary" routerLink="/produtos/bota-fora">Promoções</button>

    <button mat-button [matMenuTriggerFor]="menuMais" color="primary">
      Mais
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #menuMais="matMenu">
      <a mat-menu-item href="https://blog.tag2u.com.br" target="_blank">
        <mat-icon>open_in_new</mat-icon>
        Blog
      </a>

      <a mat-menu-item routerLink="/politica-reembolso">
        <mat-icon>refresh</mat-icon>
        Troca e reembolso
      </a>

      <button mat-menu-item [matMenuTriggerFor]="menuConta" color="primary">
        <mat-icon>person</mat-icon>
        Conta
      </button>
    </mat-menu>

    <mat-menu #menuConta="matMenu">
      <a mat-menu-item routerLink="/favoritos">Meus favoritos</a>
      <a mat-menu-item routerLink="/conta/dashboard">Minha conta</a>
      <a mat-menu-item routerLink="/conta/carteira">Minha carteira</a>
    </mat-menu>

    <button
      mat-mini-fab
      color="primary"
      aria-label="Carrinho"
      [matBadge]="produtosNoCarrinho"
      matBadgeColor="warn"
      routerLink="carrinho"
      class="checkout-btn"
    >
      <span class="material-symbols-outlined" style="color: white"> shopping_cart </span>
    </button>
  </div>

  <a routerLink="/carrinho" routerLinkActive="active" mat-icon-button class="show-on-mobile-only">
    <mat-icon class="icon" [matBadge]="produtosNoCarrinho" matBadgeColor="warn">shopping_cart</mat-icon>
  </a>

  <a routerLink="/pesquisar" routerLinkActive="active" mat-icon-button class="show-on-mobile-only">
    <mat-icon class="icon">search</mat-icon>
  </a>
</mat-toolbar>
