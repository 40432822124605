import { Injectable } from '@angular/core';
import { FetchResult, gql } from '@apollo/client/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class RegisterVendedorService {
  public _leadsSubscription: BehaviorSubject<Array<Lead>> = new BehaviorSubject([]);

  constructor(private apollo: Apollo) { }

  public criarLead(lead: Lead): Observable<FetchResult<any>> {
    const mutation = gql`
      mutation criarLeadMutation($input: CreateLeadsInputType!) {
        leads(input: $input) {
          nome
          telefone
          ativoFeito
          email
          tipo
          origem
        }
      }
    `;

    return this.apollo.mutate<any>({
      mutation,
      variables: { input: lead },
    });
  }
}

export interface CriarLeadMutationResponse {
  lead: Lead;
  loading: boolean;
}

export class Lead {
  public usuarioid: string;
  public nome: string;
  public telefone: string;
  public ativoFeito: boolean;
}
