import { VendaItem } from '@app/api';
import { Produto } from '@app/models/produto/produto.model';

export class ViewItemListModel {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon: string;
  discount: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  item_category5: string;
  item_list_id: string;
  item_list_name: string;
  item_variant: string;
  location_id: string;
  price: number;
  quantity: number;

  constructor(produto: Produto, infoAdicional?: InfoAdicional, vendaItem?: VendaItem) {
    this.item_id = produto.id;
    this.item_name = produto.tituloComposto;
    this.item_brand = produto.marca;

    if (produto.categoria) {
      this.item_category = produto.categoria.nome;
    }

    if (infoAdicional) {
      this.index = infoAdicional.index ?? 0;

      if (infoAdicional.index) {
        this.item_list_id = infoAdicional.listagem;
      }
    }

    this.quantity = vendaItem ? vendaItem.quantidade : produto.quantidadeDisponivel;
    this.price = vendaItem ? vendaItem.total : produto.valorsite;
  }
}

interface InfoAdicional {
  index?: number;
  listagem?: string;
}
