import { NgModule } from '@angular/core';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { HttpHeaders } from '@angular/common/http';
import { InternalStorage } from './api';
import { environment } from '../environments/environment';
import { v4 as uuid } from 'uuid';

export function createApollo(httpLink: HttpLink, storage: InternalStorage): ApolloClientOptions<any> {
  const uri = environment.GRAPHQL_URL;

  const http = httpLink.create({
    uri,
  });

  const middleware = new ApolloLink((operation, forward) => {
    const token = storage.get('$LoopBackSDK$id');
    let deviceId = storage.get('deviceId');

    if (!deviceId) {
      storage.set('deviceId', uuid());
    }

    if (!token) {
      return forward(operation);
    }

    operation.setContext({
      headers: new HttpHeaders().set('Authorization', token).set('deviceId', deviceId),
    });

    return forward(operation);
  });

  const link = middleware.concat(http);

  return {
    link,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  };
}

@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, InternalStorage],
    },
  ],
})
export class GraphQLModule {}
