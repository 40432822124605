import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as dayjs from 'dayjs';

export enum DADOS_COOKIE {
  SESSION_ID_CARRINHO = 'sessionIdCarrinho',
  SESSION_ID_CARRINHO_EXPIRES = 'sessionIdCarrinhoExpires',
}

@Injectable({ providedIn: 'root' })
export class CookieService {
  public constructor(@Inject(DOCUMENT) private document: Document) {}

  public get(name: DADOS_COOKIE) {
    const cookies = this.document.cookie.split('; ');

    for (let i = 0; i < cookies.length; i++) {
      const [key, value] = cookies[i].split('=');

      if (key === name) {
        return decodeURIComponent(value);
      }
    }

    return null;
  }

  public set(name: DADOS_COOKIE, value: string, expires?: string) {
    expires = expires ?? `expires=${dayjs().add(7, 'days').toDate().toUTCString()}`;

    document.cookie = `${name}=${value}; ${expires}`;
  }

  public delete(name: DADOS_COOKIE) {
    document.cookie = `${name}=; -1`;
  }
}
