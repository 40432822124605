import { Injectable } from '@angular/core';
import { Produto as ProdutoGQL } from '@app/data/produto/graphql';
import { Categoria, CategoriaApi, LoopBackFilter, Marca, MarcaApi, Produto, ProdutoApi, ProdutoFotosApi, Secao, SecaoApi } from 'app/api';
import { ProdutoCustom } from 'app/shared/classes/ProdutoCustom.class';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ProdutoService {
  constructor(
    private _produtoApi: ProdutoApi,
    private _marcaApi: MarcaApi,
    private _secaoApi: SecaoApi,
    private _categoriasApi: CategoriaApi,
    private _fotosApi: ProdutoFotosApi
  ) {
    this.getMarcas();
    this.getSecoes();
    this.getCategorias();
  }

  private _marcasWatcher$: BehaviorSubject<Marca[]> = new BehaviorSubject([]);
  private _secaoWatcher$: BehaviorSubject<Secao[]> = new BehaviorSubject([]);
  private _categoriaWatcher$: BehaviorSubject<Categoria[]> = new BehaviorSubject([]);
  public produtoFields: Array<string> = [
    'id',
    'altura',
    'largura',
    'estado',
    'valorpago',
    'valorinicial',
    'linkReferencia',
    'codProduto',
    'profundidade',
    'status',
    'updatedAt',
    'valorsite',
    'percentual',
    'tituloComposto',
    'compraTag',
    'estoque',
    'quantidadeDisponivel',
    'url',
    'hubId',
    'fabricanteId',
    'marca',
    'cor',
    'material',
    'modelo',
    'observacoes',
    'classificacao',
    'usuarioId',
    'antigoProprietarioId',
  ];

  public includeHubs: LoopBackFilter['include'] = {
    relation: 'hub',
    fields: ['id', 'cep', 'cidade', 'bairro', 'telefone'],
  };

  public get marcas$(): Observable<Marca[]> {
    return this._marcasWatcher$ as Observable<Marca[]>;
  }

  public get secoes$(): Observable<Secao[]> {
    return this._secaoWatcher$ as Observable<Secao[]>;
  }

  public get categorias$(): Observable<Categoria[]> {
    return this._categoriaWatcher$ as Observable<Categoria[]>;
  }

  private getMarcas() {
    this._marcaApi.listMarcas().subscribe(({ marcas }) => this._marcasWatcher$.next(marcas));
  }

  private getSecoes() {
    this._secaoApi.listSections().subscribe(({ secoes }) => this._secaoWatcher$.next(secoes));
  }

  private getCategorias() {
    this._categoriasApi
      .find({
        order: 'nome ASC',
      })
      .subscribe((categoria: Categoria[]) => this._categoriaWatcher$.next(categoria));
  }

  public ajustPhotos(produtos: ProdutoCustom[]): ProdutoCustom[] {
    for (const produto of produtos) {
      let index = produto.fotos.findIndex((foto) => foto.destaque);

      if (index === -1) {
        index = 0;
      }

      produto.fotourl = produto.fotos[index]?.fotoUrl;
    }

    return produtos;
  }

  public getMarca(nome: string): Promise<Marca> {
    return this._marcaApi
      .findOne<Marca>({
        where: { nome },
      })
      .toPromise();
  }

  public getSecao(url: string): Promise<Secao> {
    return this._secaoApi
      .findOne<Secao>({
        where: { url },
      })
      .toPromise();
  }

  public async getProdutos(skip: number, limit?: number, ordenacao?: string): Promise<Array<any>> {
    const where = {
      status: 'Publicado',
    };
    const produtos = await this._produtoApi
      .find({
        order: ordenacao ? ordenacao : 'dataPublicacao DESC',
        limit: limit ? limit : 12,
        skip: skip,
        where,
        fields: this.produtoFields,
        include: [this.includeHubs, 'fotos'],
      })
      .toPromise();

    return this.ajustPhotos(produtos as ProdutoCustom[]);
  }

  public async getProdutosByMarca(skip: number, limit?: number, marcaId?: string): Promise<ProdutoCustom[]> {
    const produtos = await this._produtoApi
      .find({
        where: {
          marcaId: marcaId,
          status: 'Publicado',
        },
        order: `datapublicacao DESC`,
        limit: limit ? limit : 12,
        skip: skip,
        fields: this.produtoFields,
        include: [this.includeHubs, 'fotos'],
      })
      .toPromise();

    return this.ajustPhotos(produtos as ProdutoCustom[]);
  }

  public async getProdutosMesmaMarca(produto: Produto): Promise<any> {
    const produtos = await this._produtoApi
      .find({
        order: `createdAt DESC`,
        limit: 4,
        fields: this.produtoFields,
        where: {
          id: { nlike: produto.id },
          marca: produto.marca,
          status: 'Publicado',
        },
        include: [this.includeHubs, 'fotos'],
      })
      .toPromise();
    return this.ajustPhotos(produtos as ProdutoCustom[]);
  }

  public async getProdutosMesmoDono(produto: Produto): Promise<any> {
    const produtos = await this._produtoApi
      .find({
        order: `createdAt DESC`,
        limit: 4,
        fields: this.produtoFields,
        where: {
          id: { nlike: produto.id },
          usuarioId: produto.usuarioId,
          status: 'Publicado',
        },
        include: [this.includeHubs, 'fotos'],
      })
      .toPromise();
    return this.ajustPhotos(produtos as ProdutoCustom[]);
  }

  public async searchProduto(search: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this._produtoApi
        .pesquisar(search)
        .toPromise()
        .then((res) => resolve(res.pesquisa))
        .catch((err) => reject(err));
    });
  }

  public searchProdutoObservable(search: string): Observable<any> {
    return this._produtoApi.pesquisar(search);
  }

  public setSchema(produto: ProdutoGQL): object {
    return {
      '@context': 'http://schema.org',
      '@type': 'Product',
      name: produto.titulo,
      image: produto.fotos[0].url,
      price: produto.valorsite,
      priceCurrency: 'BRL',
      sku: produto.codProduto,
      material: produto.material,
      color: produto.cor,
      brand: produto.marca,
      model: produto.modelo,
      heigth: produto.altura,
      width: produto.largura,
      depth: produto.profundidade,
      url: `https://tag2u.com.br/produto/${produto.url}`,
      offers: {
        price: produto.valorsite,
        priceCurrency: 'BRL',
        availability: produto.quantidadeDisponivel > 0 ? 'https://schema.org/InStoreOnly' : 'https://schema.org/SoldOut',
        itemCondition: produto.novo ? 'https://schema.org/NewCondition' : 'https://schema.org/UsedCondition',
      },
    };
  }
}
