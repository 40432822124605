import { RouterModule } from '@angular/router';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardProduto } from './card-produto';
import { NavigationComponent } from './navigation';
import { AtalhosComponent } from './atalhos';
import { HeaderComponent } from './header';
import { FooterComponent } from './footer';
import { LoginComponent } from './login';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { SharedModule } from '@app/shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRippleModule } from '@angular/material/core';
import { DrawerComponent } from './drawer';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { QueroDesocuparComponent } from './quero-desocupar/quero-desocupar.component';
import { PaginationComponent } from './pagination/pagination.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
    MatListModule,
    MatButtonModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    SharedModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSidenavModule,
    MatTabsModule,
    MatChipsModule,
    MatMenuModule,
    MatRadioModule,
    MatToolbarModule,
  ],
  declarations: [
    AtalhosComponent,
    CardProduto,
    DrawerComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    NavigationComponent,
    PaginationComponent,
    QueroDesocuparComponent,
  ],
  exports: [
    AtalhosComponent,
    CardProduto,
    DrawerComponent,
    FooterComponent,
    HeaderComponent,
    LoginComponent,
    MatFormFieldModule,
    MatInputModule,
    NavigationComponent,
    PaginationComponent,
    QueroDesocuparComponent,
  ],
})
export class LayoutModule {}
