import { Produto } from '@app/models';
import { DadosComContagem } from '@app/shared/classes/GraphqlQueryResponse.class';

export class Campanha {
  ativa: boolean;
  bannerMobileUrl: string;
  bannerWebUrl: string;
  codigo: string;
  destaque: DestaqueCampanha;
  id: string;
  produtosPublicados: DadosComContagem<Produto>;
  oferecerDesconto: boolean;
  produtos: Produto[];
  temBannerNaHome: boolean;
  tipo: TiposCampanha;
  tipoDesconto: TiposDescontoCampanha;
  tituloCampanha: string;
  url: string;
  valorDesconto: number;
  constructor(data?: Partial<Campanha | any>) {
    const input = { ...data };
    if (data?.tipoDesconto && typeof data.tipoDesconto === 'string') {
      input.tipoDesconto = TiposDescontoCampanha[data.tipoDesconto];
    }
    Object.assign(this, input);
  }
}
export enum TiposCampanha {
  LISTAGEM = 0,
  PRODUTO,
  ACAO_MARKETING,
}
export enum DestaqueCampanha {
  BAIXO,
  MEDIO,
  ALTO,
}
export enum TiposDescontoCampanha {
  REAIS,
  PERCENTUAL,
}

export interface ICampanhaQuery {
  campanha: Campanha;
}
