import { InjectionToken } from '@angular/core';

import { LANG_EN_NAME, LANG_EN_TRANS } from './language-en';
import { LANG_ES_NAME, LANG_ES_TRANS } from './language-es';
import { LANG_PT_NAME, LANG_PT_TRANS } from './language-pt';

const en = LANG_EN_NAME;
const es = LANG_ES_NAME;
const pt = LANG_PT_NAME;

const dictionary: any = {
    en: LANG_EN_TRANS,
    es: LANG_ES_TRANS,
    pt: LANG_PT_TRANS
};

export const TRANSLATIONS = new InjectionToken<string>('translations');

export const TRANSLATION_PROVIDERS = [{
    provide: TRANSLATIONS,
    useValue: dictionary
}];
