import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { BaseService } from '@app/shared/services/base';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  constructor(private _router: Router, private _base: BaseService) { }

  public activeThemeIsDark: boolean = localStorage.getItem('theme') === 'dark';

  public vendedorUrl: string = environment.VENDEDOR_URL;
  public showFooter: boolean;

  public year = new Date().getFullYear();

  ngOnInit() {
    this._router.events.subscribe((route: any) => {
      const targetRoute = route?.url?.routeConfig?.path;

      this.showFooter = !targetRoute?.includes('conta');
    });
  }

  get link(): string {
    const basePath = window.innerWidth > 900 ? 'web' : 'api';

    return `https://${basePath}.whatsapp.com/send?phone=${environment.TELEFONES.COMERCIAL}`;
  }

  public toggleTheme(): void {
    if (localStorage.getItem('theme') === 'dark') {
      this._base._theme.setTheme('light');
    } else {
      this._base._theme.setTheme('dark');
    }
  }

  public goToTop(): void {
    window.scrollTo(0, 0);
  }
}
