import { Injectable } from '@angular/core';

/**
 *
 *
 * @export
 * @class GuidService
 */
@Injectable()
export class GuidService {

    constructor() {

    }

    /**
     *
     *
     * @param {number} [length=0]
     * @param {any} [prefix]
     * @param {any} [suffix]
     * @returns {string}
     * @memberof GuidService
     */
    public newGuid(length = 10, prefix?, suffix?): string {
        const guid: string = Math.random().toString(36).substring(2) + (new Date()).getTime().toString(36);
        length = length === 0 || length === null ? guid.length : length;

        return prefix + guid.substr(0, length) + suffix;
    }

}
