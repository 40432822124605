import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import { NOVA_VIEW, PRODUTOS_EM_ALTA, PRODUTOS_NOVAS_PROMOS, PRODUTOS_RECEM_CHEGADOS, PRODUTOS_QUEIMA_ESTOQUE, PRODUTOS_OUTLET } from './graphql';

import { Produto } from '@app/models/produto';
import { GraphqlQueryResponse } from '@app/shared/classes/GraphqlQueryResponse.class';

@Injectable({
  providedIn: 'root',
})
export class ProdutoGQLService {
  constructor(private apollo: Apollo) {}

  public find(query: DocumentNode, variables?: any): any {
    return this.apollo.query<any>({
      query,
      variables,
    });
  }

  public getQueimaDeEstoquePaginado(skip: number = 0, take: number = 4): GraphqlQueryResponse<Produto> {
    return this.apollo.query<any>({
      query: PRODUTOS_QUEIMA_ESTOQUE,
      variables: { skip, take },
    });
  }

  public getProdutosRecemChegadosPaginado(skip: number = 0, take: number = 4): GraphqlQueryResponse<Produto> {
    return this.apollo.query<any>({
      query: PRODUTOS_RECEM_CHEGADOS,
      variables: { skip, take },
    });
  }

  public getProdutosNovasPromosPaginado(skip: number = 0, take: number = 4): GraphqlQueryResponse<Produto> {
    return this.apollo.query<any>({
      query: PRODUTOS_NOVAS_PROMOS,
      variables: { skip, take },
    });
  }

  public getProdutosEmAlta(skip: number = 0, take: number = 4): GraphqlQueryResponse<Produto> {
    return this.apollo.query<any>({
      query: PRODUTOS_EM_ALTA,
      variables: { skip, take },
    });
  }

  public getProdutosOutlet(skip: number = 0, take: number = 4): GraphqlQueryResponse<Produto> {
    return this.apollo.query<any>({
      query: PRODUTOS_OUTLET,
      variables: { skip, take },
    });
  }

  public criarView(produtoid: string): Promise<any> {
    return this.apollo
      .mutate<unknown>({
        mutation: NOVA_VIEW,
        variables: { id: produtoid },
      })
      .toPromise();
  }
}
