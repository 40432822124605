export interface Theme {
  name: string;
  properties: any;
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--primary-color': '#ffb400',

    '--body-background': '#f0f2f5',
    '--primary-color-you': '#f9d75c',
    '--color': '#424242',
    '--main-color-background': '#fff',
    '--main-color-background-hover': '#f4f4f4',
    '--main-color-background-second-level': '#fff',

    '--border-line-color': '#e4e4e4',

    '--menu-active-color': 'rgba(0,0,0, 0.1)',

    '--btn-color': '#424242',
    '--btn-background': '#bdbdbd',
    '--btn-background-hover': '#989898',

    '--input-disabled-background': '#eee',

    // '--pre-venda-color': '#7b5e0c',
    // '--pre-venda-color': '#fef0c6',

    // '--parceiros-color': '#354d09',
    // '--parceiros-background': '#b2fab4',

    // '--reversa-color': '#e65100',
    // '--reversa-background': '#ffe3d3',

    '--pre-venda-color': '#fff',

    '--parceiros-color': '#fff',

    '--reversa-color': '#fff',

    '--estado-produto-color': '#fff',

    '--observacoes-color': '#aa2c2c',
    '--observacoes-background': '#ffe0df',
  },
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--primary-color': '#ffb400',
    '--body-background': 'background-color: rgb(31, 34, 35);',
    '--primary-color-you': '#f9d75c',
    '--color': '#f4f4f4',
    '--main-color-background': '#424242',
    '--main-color-background-hover': '#807e7e',
    '--main-color-background-second-level': '#585858',
    '--border-line-color': '#f4f4f4',

    '--menu-active-color': 'rgba(0,0,0,0.2)',

    '--btn-color': '#fff',
    '--btn-background': '#989898',
    '--btn-background-hover': '#545454',

    '--input-disabled-background': '#313131',
  },
};
