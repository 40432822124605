import { Injectable } from '@angular/core';
import { Usuario } from '@app/api';
import { Carrinho } from '@app/data/carrinho/carrinho.model';
import { GraphqlMutation, GraphqlMutationResponseObject, MutationResponse } from '@app/data/common/respostas.graphql';
import { Transporte } from '@app/data/transporte/transporte.model';
import { Venda } from '@app/data/vendas';
import { Apollo, gql } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { UsuarioInput } from './inputs/usuario.input';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CarrinhoService {
  constructor(private _apollo: Apollo) {}
  private _carrinho: Carrinho;
  private carrinhoWatcher$: BehaviorSubject<Carrinho> = new BehaviorSubject(null);

  public get carrinho(): Observable<Carrinho> {
    if (!this._carrinho) {
      this._getCarrinho();
    }

    return this.carrinhoWatcher$.asObservable();
  }

  public get sessaoId(): string {
    return localStorage.getItem('sessaoId');
  }

  public atualizarCarrinho(): void {
    return this._getCarrinho();
  }

  private _getCarrinho(): void {
    const query = gql`
      query getCarrinho($sessaoId: String!) {
        carrinho: getCarrinhoBySessaoId(sessaoId: $sessaoId) {
          id
          valorTotalDosItensSemFrete
          valorTotalDosItensComFrete
          valorFrete
          usuarioId
          status
          retirarNaLoja
          vendaId
          itens {
            id
            quantidade
            produtoId
            valorTotalDoItemSemFrete
            valorUnitario
            carrinhoId
            produto {
              id
              tituloComposto
              quantidadeDisponivel
              codProduto
              dropShipping
              foto {
                url
              }
            }
          }
          usuario {
            id
            nome
            celular
            email
          }
        }
      }
    `;

    this._apollo
      .query<GetCarrinhoQueryResponse>({
        query,
        variables: { sessaoId: this.sessaoId },
        fetchPolicy: 'no-cache',
      })
      .subscribe(
        ({ data }) => {
          this._carrinho = new Carrinho(data?.carrinho);
          this.carrinhoWatcher$.next(new Carrinho(data?.carrinho));
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public transformarCarrinhoEmVenda(cliente: Partial<Usuario>, vendaB2B?: boolean, dadosTransporte?: Partial<Transporte>): GraphqlMutation<Venda> {
    const usuario = new UsuarioInput(cliente);

    const mutation = gql`
      mutation finalizarCarrinhoLoja(
        $carrinhoId: String!
        $usuario: UsuarioReservaInput!
        $vendaB2B: Boolean!
        $dadosTransporte: TransporteInputType
      ) {
        mutation: transformarCarrinhoEmVenda(carrinhoId: $carrinhoId, usuario: $usuario, vendaB2B: $vendaB2B, dadosTransporte: $dadosTransporte) {
          id
          valortotal
          valorFrete
          quantidadeVendida
          invoices {
            id
          }
          itens {
            id
            total
            quantidade
            valorRepassado
            produtoid
          }
        }
      }
    `;

    return this._apollo.mutate<MutationResponse<Venda>>({
      mutation,
      variables: { carrinhoId: this._carrinho.id, usuario, vendaB2B, dadosTransporte },
    });
  }

  public salvarTransporte(transporte: Partial<Transporte>, vendaId: String, itens: Array<any>): GraphqlMutationResponseObject<Transporte> {
    const mutation = gql`
      mutation salvarTransporte($dadosTransporte: TransporteInputType!, $produtoids: [String!]) {
        mutation: transporte(dadosTransporte: $dadosTransporte, produtoids: $produtoids) {
          id
        }
      }
    `;

    const produtoids = itens.map(({ produtoid }) => produtoid);

    return this._apollo.mutate<any>({
      mutation,
      variables: {
        dadosTransporte: {
          ...transporte,
          vendaid: vendaId,
          tipo: 'Entrega comprador',
          status: 'Pendente',
        },
        produtoids,
      },
    });
  }

  public adicionarProdutoAoCarrinho(produtoId: string, vendaB2B: boolean): Observable<unknown> {
    const mutation = gql`
      mutation adicionarProdutoNoCarrinho($sessaoId: String!, $produtoId: String!, $vendaB2B: Boolean, $distanciaMediaParaEntrega: Float!) {
        adicionarProdutoNoCarrinho(
          sessaoId: $sessaoId
          produtoId: $produtoId
          vendaB2B: $vendaB2B
          distanciaMediaParaEntrega: $distanciaMediaParaEntrega
        ) {
          id
        }
      }
    `;

    return this._apollo.mutate({
      mutation,
      variables: {
        sessaoId: this.sessaoId,
        produtoId,
        vendaB2B,
        distanciaMediaParaEntrega: JSON.parse(localStorage.getItem('endereco')).distancia_km,
      },
    });
  }

  public async remove(itemId: string, carrinhoId: string): Promise<void> {
    const mutation = gql`
      mutation removerItemMutation($itemId: String!, $carrinhoId: String!) {
        removerItemDoCarrinho(itemId: $itemId, carrinhoId: $carrinhoId)
      }
    `;

    await this._apollo
      .mutate({
        mutation,
        variables: { itemId, carrinhoId },
      })
      .toPromise()
      .then(() => this._getCarrinho());
  }

  public async increment(itemId: string, carrinhoId: string): Promise<void> {
    const mutation = gql`
      mutation incrementItemMutation($itemId: String!, $carrinhoId: String!) {
        incrementarItemCarrinho(itemId: $itemId, carrinhoId: $carrinhoId) {
          id
        }
      }
    `;

    await this._apollo
      .mutate({
        mutation,
        variables: { itemId, carrinhoId },
      })
      .toPromise()
      .then(() => this._getCarrinho());
  }

  public async decrement(itemId: string, carrinhoId: string): Promise<void> {
    const mutation = gql`
      mutation decrementItemMutation($itemId: String!, $carrinhoId: String!) {
        decrementarItemCarrinho(itemId: $itemId, carrinhoId: $carrinhoId) {
          id
        }
      }
    `;

    await this._apollo
      .mutate({
        mutation,
        variables: { itemId, carrinhoId },
      })
      .toPromise()
      .then(() => this._getCarrinho());
  }

  public toggleFrete(): Observable<unknown> {
    const mutation = gql`
      mutation toggleRetirarNaLoja($carrinhoId: String!) {
        toggleRetirarNaLoja(carrinhoId: $carrinhoId) {
          id
        }
      }
    `;

    return this._apollo.mutate({
      mutation,
      variables: { carrinhoId: this._carrinho.id },
    });
  }
}

export interface GetCarrinhoQueryResponse {
  carrinho: Carrinho;
  loading: boolean;
}

export interface CriarTransporteMutationResponse {
  salvarTransportePelaReserva: Transporte;
}
