import { AfterViewInit, Component, Injector, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { Campanha } from '@app/data/campanha';
import { ProdutoGQLService } from '@app/data/produto';
import { Produto } from '@app/models/produto';
import { BaseService } from '@app/shared/services/base';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends BaseService implements OnInit, AfterViewInit {
  constructor(private _produtoService: ProdutoGQLService, private _base: Injector, private _meta: Meta) {
    super(_base);
  }

  public produtoNovasPromocoes: Produto[];
  public produtoRecemChegados: Produto[];
  public produtosOutlet: Produto[];

  public campanhas: Array<Campanha>;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this._getProdutosOutlet();
    this._getNovasPromos();
    this._getRecemChegados();
    this._setMetaTags();
  }

  private _setMetaTags(): void {
    const description = `Tag2u - Compre e venda móveis usados. Itens para cozinha | Móveis reembalados | Itens para escritório`;
    const foto = 'https://cdn.tag2u.com.br/logos/tag2u-icone.png/';

    this._meta.addTag({ name: 'description', content: description });

    this._meta.addTag({ name: 'og:title', content: 'Tag2u' });
    this._meta.addTag({ name: 'og:description', content: description });
    this._meta.addTag({ name: 'og:url', content: `https://tag2u.com.br/` });
    this._meta.addTag({ name: 'og:image', content: foto });
    this._meta.addTag({ name: 'og:site_name', content: 'Tag2u - Comprar e vender usados' });

    this._meta.addTag({ name: 'twitter:title', content: 'Tag2u' });
    this._meta.addTag({ name: 'twitter:description', content: description });
    this._meta.addTag({ name: 'twitter:url', content: `https://tag2u.com.br/` });
    this._meta.addTag({ name: 'twitter:image', content: foto });
    this._meta.addTag({ name: 'twitter:site_name', content: 'Tag2u - Comprar e vender usados' });
  }

  private _getNovasPromos() {
    this._produtoService
      .getProdutosNovasPromosPaginado()
      .subscribe(({ data }) => {
        this.produtoNovasPromocoes = data.query.dados;
      })
      .add(() => this.analyticsEvents.listagemDeItens(this.produtoNovasPromocoes, 'novas_promocoes_home'));
  }

  private _getRecemChegados() {
    this._produtoService
      .getProdutosRecemChegadosPaginado()
      .subscribe(({ data }) => {
        this.produtoRecemChegados = data.query.dados;
      })
      .add(() => this.analyticsEvents.listagemDeItens(this.produtoRecemChegados, 'recem_chegados_home'));
  }

  private _getProdutosOutlet() {
    this._produtoService
      .getProdutosOutlet()
      .subscribe(({ data }) => {
        this.produtosOutlet = data.query.dados;
      })
      .add(() => this.analyticsEvents.listagemDeItens(this.produtosOutlet, 'outlet_home'));
  }
}
