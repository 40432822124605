import { ErrorModule } from './error/error.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';
import { SDKBrowserModule, LoopBackConfig } from 'app/api';
import { environment } from 'environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AuthModule } from './auth/auth.module';
import { PagesModule } from './pages/pages.module';
import { GraphQLModule } from './graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { MatSidenavModule } from '@angular/material/sidenav';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(pt);

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserAnimationsModule,
    CoreModule,
    ErrorModule,
    FormsModule,
    GraphQLModule,
    HttpClientModule,
    LayoutModule,
    MatSidenavModule,
    PagesModule,
    RouterModule,
    SDKBrowserModule.forRoot(),
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-br' }],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    LoopBackConfig.setBaseURL(environment.API_URL);
    LoopBackConfig.setApiVersion(environment.API_VERSION);
    LoopBackConfig.setDebugMode(environment.API_DEBUG);
  }
}
