import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ProdutoGQLService } from '@app/data/produto/produto.gql.service';
import { Produto } from '@app/models/produto';
import { HomeModule } from '@app/pages/home/home.module';
import { BaseService } from '@app/shared/services/base';

@Component({
  selector: 'app-lellolab-colab',
  templateUrl: './lellolab-colab.component.html',
  styleUrls: ['./lellolab-colab.component.scss'],
  standalone: true,
  imports: [CommonModule, MatCardModule, HomeModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LelloLabColabComponent implements OnInit {
  constructor(private readonly _base: BaseService, private _produtoService: ProdutoGQLService) {}

  ngOnInit(): void {
    this._base._titleService.setTitle('Tag2u & Lello');
    this._getProdutosOutlet();
  }

  public produtosOutlet: Produto[];

  private _getProdutosOutlet() {
    this._produtoService
      .getProdutosOutlet()
      .subscribe(({ data }) => {
        this.produtosOutlet = data.query.dados;
      })
      .add(() => this._base.analyticsEvents.listagemDeItens(this.produtosOutlet, 'outlet_home'));
  }
}
