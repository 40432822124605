<div class="card" [class]="customClass" itemscope itemtype="https://schema.org/Product">
  <div class="categoria-marker estado-{{ produto.estado }}" [ngClass]="{ 'categoria-pre-venda': produto.status === 'Aprovado' }">
    {{ textoCategoria(produto) }}
  </div>

  <div *ngIf="produto?.estado" class="marker-estado estado-{{ produto.estado }}" [ngClass]="{ 'estado-pre-venda': produto.status === 'Aprovado' }">
    {{ produto?.estado }}
  </div>

  <div *ngIf="produto.status === 'Aprovado'" class="pre-venda-marker">Pré-venda</div>

  <div class="cardImage" #cardImage *ngIf="produto?.foto?.url"></div>

  <div class="listing-item-content-produto">
    <h3
      *ngIf="produto?.valorsite?.toString() != 'NaN' && produto.valorsite != 0"
      class="preco-produto-card"
      itemprop="offers"
      itemscope
      itemtype="https://schema.org/Offer"
    >
      <!-- Valor inicial caso não haja campanha ativa, -->
      <span class="old-price" *ngIf="desconto && desconto > 0 && !valorComDescontoCampanha">
        {{ ((produto?.reversa || produto?.novo) ? produto.valorNovoCheio : produto.valorinicial) | _currency }}
      </span>

      <!-- Valor real sem desconto caso tenha campanha ativa -->
      <span class="old-price" *ngIf="valorComDescontoCampanha">
        {{ produto.valorinicial | _currency }}
      </span>
      <span class="percentil-off" *ngIf="desconto > 0 && !produtoB2B">{{ desconto }}% OFF</span>

      <br />
      <span class="quant pull-right">Disp: {{ produto?.quantidadeDisponivel }}</span>

      <span itemprop="priceCurrency" content="BRL" class="price"> R$ </span>
      <span itemprop="price" class="price" *ngIf="!produtoB2B || (!produto?.reversa && !produto.novo)">
        <span *ngIf="produto.campanhasAtivas?.length < 1">{{ produto.valorsite }}</span>

        <span *ngIf="produto.campanhasAtivas">{{ valorComDescontoCampanha }}</span>
      </span>

      <span itemprop="price" class="price" *ngIf="!!produtoB2B && (produto?.reversa || produto.novo)">
        <span *ngIf="produto.campanhasAtivas">{{ produto?.valorB2B }}</span>
      </span>
    </h3>

    <h3 *ngIf="produto.valorsite == 0">Em análise</h3>

    <h5 itemprop="name">{{ produto?.tituloComposto || produto?.titulocomposto }}</h5>

    <span *ngIf="!!valorComDescontoCampanha" class="card-campanha">
      {{ campanha.tituloCampanha }}
    </span>
  </div>
</div>
