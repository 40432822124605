/* tslint:disable */
import {
  Venda
} from '../index';

declare var Object: any;
export interface PagamentoInterface {
  "id"?: string;
  "valor"?: number;
  "status"?: string;
  "metodo"?: string;
  "taxa"?: number;
  "createdAt"?: Date;
  "updatedAt"?: Date;
  "vendaId"?: string;
  venda?: Venda;
}

export class Pagamento implements PagamentoInterface {
  "id": string;
  "valor": number;
  "status": string;
  "metodo": string;
  "taxa": number;
  "createdAt": Date;
  "updatedAt": Date;
  "vendaId": string;
  venda: Venda;
  constructor(data?: PagamentoInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `Pagamento`.
   */
  public static getModelName() {
    return "Pagamento";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of Pagamento for dynamic purposes.
  **/
  public static factory(data: PagamentoInterface): Pagamento{
    return new Pagamento(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'Pagamento',
      plural: 'Pagamentos',
      path: 'Pagamentos',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "valor": {
          name: 'valor',
          type: 'number',
          default: 0
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "metodo": {
          name: 'metodo',
          type: 'string'
        },
        "taxa": {
          name: 'taxa',
          type: 'number',
          default: 0
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
        "vendaId": {
          name: 'vendaId',
          type: 'string'
        },
      },
      relations: {
        venda: {
          name: 'venda',
          type: 'Venda',
          model: 'Venda',
          relationType: 'belongsTo',
                  keyFrom: 'vendaId',
          keyTo: 'id'
        },
      }
    }
  }
}
