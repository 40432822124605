import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Usuario } from '@app/api';
import { UsuarioService } from '@app/data';
import { ContactsEnum } from '@app/shared/enumerators/contacts.enum';
import { CUSTOM_MASKS } from '@app/shared/services';
import { BaseService } from '@app/shared/services/base';
import { AuthService } from '..';
import { Lead, RegisterVendedorService } from './register-vendedor.service';
import { environment } from '@env/index';

@Component({
  selector: 'app-register-vendedor',
  templateUrl: './register-vendedor.component.html',
  styleUrls: ['./register-vendedor.component.scss'],
})
export class RegisterVendedorComponent implements OnInit {
  public usuario: Usuario = new Usuario();
  public confirm: string;
  public loading = false;
  public returnUrl: string;
  public masks = CUSTOM_MASKS.masks;
  public contactType: number = ContactsEnum.MOBILE;
  public contactsEnum = ContactsEnum;

  public leadForm: UntypedFormGroup = new UntypedFormGroup({
    nome: new UntypedFormControl('', [Validators.required]),
    telefone: new UntypedFormControl('', [Validators.required]),
    email: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    origem: new UntypedFormControl('quero-vender'),
    tipo: new UntypedFormControl('ANGARIACAO'),
  });

  constructor(
    private _base: BaseService,
    private router: Router,
    private route: ActivatedRoute,
    private _registerVendedorService: RegisterVendedorService,
    private _auth: AuthService
  ) { }

  public ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/login';
  }

  public get tipoContato() {
    const tipos = ['Celular (Br)', 'Telefone', 'Celular (US)', 'Celular (outros)'];

    return tipos[this.contactType];
  }

  public async checkData(e: any): Promise<void> {
    e.preventDefault();

    this.saveUser();
  }

  public saveUser(): void {
    this._base._loading.start();

    const { ...lead } = this.leadForm.value;

    this._registerVendedorService.criarLead(lead).subscribe(
      () => {
        this._base._alert.success('Recebemos seus dados!', 'Agora um representante entrará com você o mais breve possível');
        this._base._loading.stop();
      },
      (err) => {
        this._base._alert.error('Atenção', err.message);
        this._base._loading.stop();
      }
    );
  }

  public login(): void {
    this._auth
      .login(this.usuario)
      .then(() => {
        this._base._toast.success('Login realizado com sucesso');
        this.router.navigateByUrl('/conta');
        this._base._loading.stop();
      })
      .catch((error) => {
        this._base._toast.error(error);
      });
  }

  public getDropDownText(): string {
    if (this.contactType === ContactsEnum.TELEPHONE) {
      return 'Telefone';
    }
    if (this.contactType === ContactsEnum.MOBILE) {
      return 'Celular';
    }
    if (this.contactType === ContactsEnum.ANOTHER) {
      return 'Outro';
    }
  }

  public setContactType(contactType: number): void {
    this.contactType = contactType;

    this.usuario.celular = undefined;
  }

  get link(): string {
    const text = `Olá, tudo bem? Tenho interesse em vender meus produtos`;
    const basePath = window.innerWidth > 900 ? 'web' : 'api';

    return `https://${basePath}.whatsapp.com/send?phone=${environment.TELEFONES.COMERCIAL}&text=${text}`;
  }
}
