import { NgModule, ModuleWithProviders } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { ToastService } from './toast.service';

@NgModule({})
export class ToastModule {
  constructor() {}

  public static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: ToastModule,
      providers: [ToastService, ToastrService],
    };
  }
}
