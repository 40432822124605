import { Routes, RouterModule } from '@angular/router';

import { ResetComponent } from './reset/reset.component';
import { SetpasswordComponent } from './setpassword/setpassword.component';
import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { RegisterLoginComponent } from './register-login/register-login.component';
import { RegisterVendedorComponent } from './register-vendedor/register-vendedor.component';
import { LinkMagicoComponent } from './linkmagico/linkmagico.component';

const routes: Routes = [
  { path: 'login', redirectTo: 'auth' },
  { path: 'cadastrar', redirectTo: 'auth' },
  { path: 'register', redirectTo: 'auth' },
  { path: 'linkmagico/:chave', component: LinkMagicoComponent },
  { path: 'redefinir-senha', component: ResetComponent },
  { path: 'setpassword', component: SetpasswordComponent },
  { path: 'changepassword', component: ChangepasswordComponent },
  { path: 'auth', component: RegisterLoginComponent },
  { path: 'quero-vender', component: RegisterVendedorComponent },
];

export const AuthRoutingModule = RouterModule.forChild(routes);
