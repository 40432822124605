<!-- Titlebar
================================================== -->
<div id="titlebar">
	<div class="container">
		<div class="row">
			<div class="col-md-12">

				<h2>404 Not Found</h2>

				<!-- Breadcrumbs -->
				<nav id="breadcrumbs">
					<ul>
						<li><a href="#">Home</a></li>
						<li>404 Not Found</li>
					</ul>
				</nav>

			</div>
		</div>
	</div>
</div>


<!-- Content
================================================== -->


<!-- Container -->
<div class="container">

	<div class="row">
		<div class="col-md-12">

			<section id="not-found" class="center">
				<h2>4<i class="im im-icon-Depression"></i>4</h2>
				<p>Oopss, parece que a página que procura não existe.</p>

				<!-- Search -->
				<div class="row">
					<div class="col-lg-8 col-lg-offset-2">
						<div class="main-search-input gray-style margin-top-50 margin-bottom-10">
							<div class="main-search-input-item">
								<input type="text" placeholder="O que você está procurando?" value="" />
							</div>

							<button class="button">Pesquisar</button>
						</div>
					</div>
				</div>
				<!-- <app-search-widget></app-search-widget> -->
				<!-- Search Section / End -->


			</section>

		</div>
	</div>

</div>
<!-- Container / End -->