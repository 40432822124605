/* tslint:disable */
import { Usuario } from '../index';

declare var Object: any;
export interface DadosBancarioInterface {
  id?: string;
  banco?: string;
  agencia?: string;
  conta?: string;
  digito?: string;
  tipoconta?: string;
  titular?: string;
  documentoTitular?: string;
  usuarioId?: string;
  createdAt: Date;
  updatedAt: Date;
  usuario?: Usuario;
}

export class DadosBancario implements DadosBancarioInterface {
  'id': string;
  'banco': string;
  'agencia': string;
  'conta': string;
  'digito': string;
  'tipoconta': string;
  'titular': string;
  'documentoTitular': string;
  'usuarioId': string;

  'createdAt': Date;
  'updatedAt': Date;
  usuario: Usuario;
  constructor(data?: DadosBancarioInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `DadosBancario`.
   */
  public static getModelName() {
    return 'DadosBancario';
  }
  /**
   * @method factory
   * @author Jonathan Casarrubias
   * @license MIT
   * This method creates an instance of DadosBancario for dynamic purposes.
   **/
  public static factory(data: DadosBancarioInterface): DadosBancario {
    return new DadosBancario(data);
  }
  /**
   * @method getModelDefinition
   * @author Julien Ledun
   * @license MIT
   * This method returns an object that represents some of the model
   * definitions.
   **/
  public static getModelDefinition() {
    return {
      name: 'DadosBancario',
      plural: 'DadosBancario',
      path: 'DadosBancario',
      idName: 'id',
      properties: {
        id: {
          name: 'id',
          type: 'string',
        },
        banco: {
          name: 'banco',
          type: 'string',
        },
        agencia: {
          name: 'agencia',
          type: 'string',
        },
        conta: {
          name: 'conta',
          type: 'string',
        },
        digito: {
          name: 'digito',
          type: 'string',
        },
        tipoconta: {
          name: 'tipoconta',
          type: 'string',
        },
        titular: {
          name: 'titular',
          type: 'string',
        },
        documentoTitular: {
          name: 'documentoTitular',
          type: 'string',
        },
        usuarioId: {
          name: 'usuarioId',
          type: 'string',
        },
        createdAt: {
          name: 'createdAt',
          type: 'Date',
        },
        updatedAt: {
          name: 'updatedAt',
          type: 'Date',
        },
      },
      relations: {
        usuario: {
          name: 'usuario',
          type: 'Usuario',
          model: 'Usuario',
          relationType: 'belongsTo',
          keyFrom: 'usuarioId',
          keyTo: 'id',
        },
      },
    };
  }
}
