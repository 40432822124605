import { Component, Input } from '@angular/core';
import { Produto } from '@app/data';
import { BaseService } from '@app/shared/services/base';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'home-slider-produtos',
  templateUrl: './home-slider-produtos.component.html',
  styleUrls: ['./home-slider-produtos.component.scss'],
})
export class HomeSliderProdutosComponent {
  constructor(private _base: BaseService) {}

  @Input() titulo: string;
  @Input() id_listagem: string;
  @Input() url: string;
  @Input() produtos: Array<Produto>;

  public carrouselProdutos: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    skip_validateItems: true,
    fluidSpeed: true,
    lazyLoad: true,
    dots: true,
    navSpeed: 500,
    autoplay: false,
    autoplayHoverPause: true,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1.3,
      },
      400: {
        items: 1.3,
      },
      740: {
        items: 1.3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  public processarEventoDeClick(produto: Produto): void {
    this._base.analyticsEvents.selecaoDeItem(produto, this.id_listagem);
  }
}
