import { gql } from 'apollo-angular';

export const NOVA_VIEW = gql`
  mutation adicionarViewNoProduto($id: String!) {
    view(produtoid: $id) {
      produto {
        viewsCount
      }
    }
  }
`;
