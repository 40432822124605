/* tslint:disable */
import {
  Venda,
  Produto
} from '../index';

declare var Object: any;
export interface VendaItemInterface {
  "id"?: string;
  "quantidade": number;
  "status"?: string;
  "valorRepassado"?: number;
  "valorIndividual": number;
  "desconto": number;
  "total": number;
  "taxaFinanciamento"?: number;
  "observacao"?: string;
  "transacaoId"?: string;
  "vendaId"?: string;
  "produtoId"?: string;
  "createdAt": Date;
  "updatedAt": Date;
  campanhaid: string;
  venda?: Venda;
  produto?: Produto;
}

export class VendaItem implements VendaItemInterface {
  "id": string;
  "quantidade": number;
  "status": string;
  "valorRepassado": number;
  "valorIndividual": number;
  "desconto": number;
  "total": number;
  "taxaFinanciamento": number;
  "observacao": string;
  "transacaoId": string;
  "vendaId": string;
  "produtoId": string;
  "createdAt": Date;
  "updatedAt": Date;
  campanhaid: string;

  venda: Venda;
  produto: Produto;
  constructor(data?: VendaItemInterface) {
    Object.assign(this, data);
  }
  /**
   * The name of the model represented by this $resource,
   * i.e. `VendaItem`.
   */
  public static getModelName() {
    return "VendaItem";
  }
  /**
  * @method factory
  * @author Jonathan Casarrubias
  * @license MIT
  * This method creates an instance of VendaItem for dynamic purposes.
  **/
  public static factory(data: VendaItemInterface): VendaItem {
    return new VendaItem(data);
  }
  /**
  * @method getModelDefinition
  * @author Julien Ledun
  * @license MIT
  * This method returns an object that represents some of the model
  * definitions.
  **/
  public static getModelDefinition() {
    return {
      name: 'VendaItem',
      plural: 'VendaItem',
      path: 'VendaItem',
      idName: 'id',
      properties: {
        "id": {
          name: 'id',
          type: 'string'
        },
        "quantidade": {
          name: 'quantidade',
          type: 'number',
          default: 1
        },
        "status": {
          name: 'status',
          type: 'string'
        },
        "valorRepassado": {
          name: 'valorRepassado',
          type: 'number',
          default: 0
        },
        "valorIndividual": {
          name: 'valorIndividual',
          type: 'number',
          default: 0
        },
        "desconto": {
          name: 'desconto',
          type: 'number',
          default: 0
        },
        "total": {
          name: 'total',
          type: 'number',
          default: 1
        },
        "taxaFinanciamento": {
          name: 'taxaFinanciamento',
          type: 'number',
          default: 0
        },
        "observacao": {
          name: 'observacao',
          type: 'string'
        },
        "transacaoId": {
          name: 'transacaoId',
          type: 'string'
        },
        "vendaId": {
          name: 'vendaId',
          type: 'string'
        },
        "produtoId": {
          name: 'produtoId',
          type: 'string'
        },
        "createdAt": {
          name: 'createdAt',
          type: 'Date'
        },
        "updatedAt": {
          name: 'updatedAt',
          type: 'Date'
        },
      },
      relations: {
        venda: {
          name: 'venda',
          type: 'Venda',
          model: 'Venda',
          relationType: 'belongsTo',
          keyFrom: 'vendaId',
          keyTo: 'id'
        },
        produto: {
          name: 'produto',
          type: 'Produto',
          model: 'Produto',
          relationType: 'belongsTo',
          keyFrom: 'produtoId',
          keyTo: 'id'
        },
      }
    }
  }
}
