interface Scripts {
  name: string;
  src: string;
}
export const ScriptsStore: Scripts[] = [
  { name: 'jquery', src: 'assets/theme/scripts/jquery-2.2.0.min.js' },
  { name: 'mmenu', src: 'assets/theme/scripts/mmenu.min.js' },
  { name: 'slick', src: 'assets/theme/scripts/slick.min.js' },
  { name: 'jquery-ui', src: 'assets/theme/scripts/jquery-ui.min.js' },
  { name: 'tooltips', src: 'assets/theme/scripts/tooltips.min.js' },
  { name: 'custom', src: 'assets/theme/scripts/custom.js' },
];
