import { Injectable } from '@angular/core';
import { Carrinho } from '@app/data/carrinho/carrinho.model';
import { CarrinhoItem } from '@app/data/carrinhoItem/carrinho-item.model';
import { Invoice } from '@app/data/invoice/invoice.model';
import { Produto } from '@app/models/produto/produto.model';
import { ViewItemListModel } from './models';

/**
 * Thanks to "beingcoders" for the article on medium: https://medium.com/beingcoders/how-to-use-google-analytics-in-angular-9a6131979819
 * @harry
 */
declare const gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsEventsService {
  public listagemDeItens(produtos: Array<Produto>, listagem: string): void {
    const items = produtos.map((produto, index) => {
      return { ...new ViewItemListModel(produto, { index, listagem }) };
    });

    gtag('event', 'view_item_list', {
      item_list_id: listagem,
      item_list_name: listagem,
      items,
    });
  }

  public selecaoDeItem(produto: Produto, listagem: string): void {
    const item = { ...new ViewItemListModel(produto, { index: 0, listagem }) };

    gtag('event', 'select_item', {
      item_list_id: listagem,
      item_list_name: listagem,
      items: [item],
    });
  }

  public itemVisualizado(produto: Produto): void {
    const item = { ...new ViewItemListModel(produto, { index: 0 }) };

    gtag('event', 'view_item', {
      currency: 'BRL',
      value: produto.valorsite,
      items: [item],
    });
  }

  public itemAdicionadoNoCarrinho(produto: Produto): void {
    const item = { ...new ViewItemListModel(produto, { index: 0 }) };

    gtag('event', 'add_to_cart', {
      currency: 'BRL',
      value: produto.valorsite,
      items: [item],
    });
  }

  public itemRemovidoDoCarrinho(produto: Produto): void {
    const item = { ...new ViewItemListModel(produto, { index: 0 }) };

    gtag('event', 'remove_from_cart', {
      currency: 'BRL',
      value: produto.valorsite,
      items: [item],
    });
  }

  /**
   *
   * @param carrinho Carrinho atual
   * @param produtos Produtos no carrinho
   */
  public visualizarCarrinho(carrinho: Carrinho, itens: Array<CarrinhoItem>): void {
    const produtos = itens?.map(({ produto }) => produto);
    const items = produtos?.map((produto, index) => {
      return { ...new ViewItemListModel(produto, { index }) };
    });

    gtag('event', 'view_cart', {
      currency: 'BRL',
      value: carrinho.valorTotalDosItensComFrete,
      items: items,
    });
  }

  public iniciarCheckout(carrinho: Carrinho, itens: Array<CarrinhoItem>): void {
    if (itens) {
      const produtos = itens.map(({ produto }) => produto);
      const items = produtos.map((produto, index) => {
        return { ...new ViewItemListModel(produto, { index }) };
      });

      gtag('event', 'begin_checkout', {
        currency: 'BRL',
        value: carrinho.valorTotalDosItensComFrete,
        items: items,
      });
    }
  }

  public clienteEncerrouCarrinho(invoice: Invoice): void {
    const items = invoice.venda.itens.map((item, index) => {
      return { ...new ViewItemListModel(item.produto as any, { index }, item) };
    });

    gtag('event', 'purchase', {
      currency: 'BRL',
      value: invoice.valor,
      transaction_id: invoice.id,
      items,
    });
  }
}
