import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { LoopBackAuth } from 'app/api/services/';
import { SDKToken } from 'app/api/models/BaseModels';

@Injectable()
export class LinkMagicoService {
  constructor(@Inject(LoopBackAuth) protected _loopbackAuth: LoopBackAuth, private _apollo: Apollo) {}

  public async loginComLinkMagico(token: string): Promise<void> {
    const mutation = gql`
      mutation loginLinkMagicoLoja($token: String!) {
        loginLinkMagico(token: $token) {
          id
          ttl
          scopes
          created
          userId
          user {
            id
          }
        }
      }
    `;

    const authData = await this._apollo
      .mutate<LoginLinkMagicoResponse>({
        mutation,
        variables: {
          token,
        },
      })
      .toPromise()
      .catch((err) => {
        throw new Error(err);
      });

    this._loopbackAuth.setToken({ ...authData.data.loginLinkMagico });
  }
}

interface LoginLinkMagicoResponse {
  loginLinkMagico: SDKToken;
}
